import React, { useEffect, useState } from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField} from "@material-ui/core";

const RevenueDialog = (props) => {


    return(
        <>
            <Dialog open={props.open.isOpen} onClose={props.handleClose}
                    aria-labelledby="form-dialog-title" maxWidth='xs' fullWidth={true}>
                <DialogTitle id="form-dialog-title">Revenue Percentage</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="revenuePercentage"
                        value={props.revPercentValue}
                        label="revenue percentage"
                        min='0'
                        max='40'
                        type="number"
                        fullWidth
                        onChange={props.handleRevenuePercent}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={props.addRevenuePercent} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default RevenueDialog;