import {io} from "socket.io-client";

const url = "https://service.bbn.org.in";

export const socket = io(url, {
    autoConnect: true,
    auth: {userId : "", type: "Web"},
    extraHeaders:{
        "ngrok-skip-browser-warning":"69420"
    }
})

socket.onAny((event, ...args) => {
    console.log(event, args, "socket");
})