import React, {useState} from "react";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {
    TextField,
    makeStyles,
    Container,
    Avatar,
    Typography,
    Grid,
    Paper,
    Button,
} from "@material-ui/core";
import _ from "lodash";
import {Player, Controls} from "@lottiefiles/react-lottie-player";
import {crud} from "../services/CrudFactory";
import {Navigate, useNavigate} from "react-router-dom";
import {getClientToken} from "../services/firebase";

const useStyles = makeStyles((theme) => ({
    container: {
        background: "#115ECE",
        // background: "#115ECE",
        height: "100vh",
    },
    login: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    },
    paper: {
        width: "700px",
        display: "flex",
        alignItems: "center",
        // padding: "20px",
        borderRadius: "10px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            flexDirection: "column"
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "var(--main-color)",
    },
    form: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rightSide: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, .15)",
        backdropFilter: "blur(5px)",
        padding: "20px",
    },
}));
export default function Login() {
    const history = useNavigate();
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(false);
    const [isTokenFound,setIsTokenFound] = useState(false)
    function valueChange(e) {
        const {value, name} = e.target;
        setParams({...params, [name]: value});
    }

    const Login = async () => {
        const fcm_token = await getClientToken(setIsTokenFound);
        Object.assign(params,{fcm_token});
        const mobileRegex = /^[0]?[6789]\d{9}$/;
        let error = [];
        if (_.isEmpty(params.mobile)) {
            error.push("Mobile Is Required");
        } else if (params.mobile && !mobileRegex.test(params.mobile)) {
            error.push("Valid Mobile number Is Required");
        } else if (_.isEmpty(params.password)) {
            error.push("Password Is Required");
        }

        if (!_.isEmpty(error)) {
            await crud.notify({
                message: error,
                type: "error",
            });
        } else {
            try {
                setLoading(true);
                const {data} = await crud.post("create/web/login", params);
                localStorage.setItem("user-info", JSON.stringify(data));
                localStorage.setItem("login_token", data.token);
                history("/dashboard");
            } catch (error) {
                setLoading(false);
                console.log("Error" + error);
            }
        }
    };

    const classes = useStyles();
    const isAuthenticated = localStorage.getItem("user-info");
    if (!_.isEmpty(isAuthenticated)) {
        return <Navigate to="/dashboard"/>;
    }
    return (
        <div className={classes.container}>
            <Container maxWidth={"xl"} className={classes.container}>
                <div className={classes.login}>

                    <Paper elevation={3} className={classes.paper}>
                        <Grid container justify="center">
                            <Grid item xs={12} sm={6} md={6} style={{background: "var(--main-color)"}}>
                                <Player
                                    autoplay
                                    loop
                                    src="https://assets4.lottiefiles.com/packages/lf20_sop8cbmc.json"
                                    style={{height: "280px", width: "300px", marginTop: "25px"}}
                                >
                                    <Controls visible={false}/>
                                </Player>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} className={classes.rightSide}>
                                <form
                                    className={classes.form}
                                    noValidate
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        Login();
                                    }}
                                >
                                    <Avatar className={classes.avatar}>
                                        <LockOutlinedIcon/>
                                    </Avatar>
                                    <Typography component="h1" variant="h5" color="primary">
                                        Sign in
                                    </Typography>
                                    <TextField
                                        name="mobile"
                                        label="Mobile number"
                                        variant="outlined"
                                        size="small"
                                        inputProps={{maxLength: 10}}
                                        margin="normal"
                                        onChange={(e) => valueChange(e)}
                                    />
                                    <TextField
                                        onChange={(e) => valueChange(e)}
                                        name="password"
                                        type="password"
                                        label="Password"
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        style={{background: "var(--main-color)", color: "#fff"}}
                                        className={classes.submit}
                                    >
                                        {loading ? "Signing In..." : "Sign In"}
                                    </Button>
                                </form>
                            </Grid>
                        </Grid>
                    </Paper>

                </div>
            </Container>
        </div>
    );
}
