import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const MonthYearDialog= ()=>{
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                // disableToolbar
                variant="inline"
                views={['year', 'month']}
                format="MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Select month and year"
                value={selectedDate}
                onChange={handleDateChange}
                minDate={new Date('2020-01-01')}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </MuiPickersUtilsProvider>
    );
}

export default MonthYearDialog;
