import React, {Fragment, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {
    DialogTitle, DialogContent, DialogActions, Grid, TextField, Select, MenuItem, FormControl, DialogContentText
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {crud} from "../services/CrudFactory";
import {$realTime} from "../services/Websocket";
import Divider from '@material-ui/core/Divider';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import 'date-fns';
// import useRazorpay from "react-razorpay";
import axios from 'axios';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    fieldtitle: {
        minWidth: "300px !important", maxHeight: "250px"
    },
    gridItem: {
        marginTop: "25px",
        // '@media (min-width:600px)': {
        //     marginTop: '25px',
        // },
    },
    // '& .MuiDialogContent-root': {
    //     width: '600px',
    //     height: '400px',
    //     [theme.breakpoints.down('sm')]: {
    //         width: '90%', // Adjust for smaller screens
    //     },
    // },
}));

export default function JobCreate(props) {
    const classes = useStyles();
    const [subCategories, setSubCategories] = useState([]);
    const [professionalIds, setProfessionalIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState({});
    const [type, setType] = useState(null);
    const [categories, setCategories] = useState([]);
    const [state, setState] = useState("");
    const [showField, setShowField] = useState("success");
    const [showOTPField, setShowOTPField] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [order, setOrder] = useState({});
    const [formAddress, setFormAddress] = useState({})
    const [open, setOpen] = useState(false);
    const [services, setServices] = useState([])
    // const [Razorpay] = useRazorpay();

    // const retrieveProfessionals = async () => {
    //     const {data: {active_professionals}} = await crud.get("retrieve/web/professionals-for-assign-jobs", {
    //         categoryId: order?.categoryId
    //     });
    //     setActiveProfessionals(active_professionals);
    // }


    const addAddress = async () => {
        try {
            const {data: {allAddress}} = await crud.post("create/web/customer-address", {
                ...formAddress,
                id: params.id
            });
            // console.log(allAddress,"=========allAddress")
            setParams({...params, customerAddress: allAddress})
        } finally {
            setOpen(false)
        }
    }

    const handleOpenDialog = () => {
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setOpen(false);
    };
    const getServices = async (id) => {
        const {data: {services}} = await crud.get("retrieve/web/services-by-id", {id});
        // console.log(data,"Get Services ")
        setServices(services)

    }

    const getSubCategories = async (id) => {
        const {data: {subCategoryNames}} = await crud.get("retrieve/web/subCategories-by-id", {id});
        setSubCategories(subCategoryNames)
    }
    const categoriesNames = async () => {
        const {data: {categoriesNames}} = await crud.get("retrieve/web/categories-names");
        // console.log(categoriesNames,"======categories Name")
        setCategories(categoriesNames);
    }

    const assignOrder = async () => {
        // console.log(params)
        props.onClose();
        props.onSave();
        // console.log(params, "-----PARAMS")
        // try {
        //     setParams({})
        //
        // } finally {
        //     setLoading(false);
        // }
    }
    const createOrder = async () => {
        try {


            const {data} = await crud.post("create/web/order", order);
            props.onClose()

        } catch (e) {
            console.log(e.message)
        }

    }
    // console.log(order,"=====order")

    const InputChange = async (e) => {
        const {name, value} = e.target;
        // console.log(name)
        // console.log(value)
        if (name == "address" || name == "pincode" || name == "city" || name == "state" || name == "lat_lng" || name == "landmark") {
            setParams({...params, "customerAddress": {...params?.customerAddress, [name]: value}})
        } else {
            setParams({...params, [name]: value});
        }
    }

    const orderDetails = (e) => {
        const {name, value} = e.target;
        if (name === "categoryId") {
            getSubCategories(value)
        } else if (name === "subCategoryId") {
            getServices(value)
        }
        setOrder(prev => ({...prev, [name]: value}));
    };


    const updateUser = async () => {
        // console.log(params, "-----params---")
        const data = await crud.post("update/web/customer/profile", params);
        // console.log(data, "-----====data");
    }
    const Check = async () => {
        try {

            const data = await crud.get("retrieve/web/check-customer", params);
            // console.log(data)
            setType(data.type)
            setParams(data?.data?.isCustomerExists)
            // onUserInputAddress()
            setShowField(data?.type);
            console.log({
                target: {
                    name: "customerId", value: data.data.isCustomerExists.id
                }
            })
            orderDetails({target: {name: "customerId", value: data.data.isCustomerExists.id}})

        } catch (e) {
            const {name, mobile} = e.errors.customer;
            setParams({
                name: name,
                check_mobile: mobile
            });

            if (e.message === "Customer not Exist") {
                setShowField(true)
                setType(e.type)
            }

        }
    }

    const SendOTP = async () => {
        const {data, type} = await crud.post("create/web/customer", {mobile: params.check_mobile});

        if (type === "success") {
            setShowOTPField(true)
            setParams(prev => ({...prev, userId: data.customer.id, otp: data.otp.otp}))
        }
    }

    const VerifyOTP = async () => {

        const data = await crud.post("create/app/customer/match-otp", {userId: params.userId, otp: params.otp});

        // console.log(data, "======data")

        if (data.type === "success") {
            setShowOTPField(false);
            setDisabled(true);
            setParams(prev => ({...prev, id: data?.data?.customer.id, userId: ""}))
            orderDetails({target: {name: "customerId", value: data.data.customer.id}})

        }
    }
    // console.log(params)
    useEffect(() => {
        // retrieveProfessionals();
        categoriesNames();
    }, [order]);
    useEffect(() => {

    }, [params?.mobile])
    // console.log(showField)
    // console.log(params, '----------------params')


    const onUserInputAddress = (e) => {
        setFormAddress({
            ...formAddress,
            [e.target.name]: e.target.value
        })
    }
    // console.log(formAddress,'================key');


    return (<Fragment>
        <Dialog
            // onClose={props.close}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            maxWidth={"md"}
            TransitionProps={{
                onEnter: () => {
                    setOrder(props.assign);
                    if (props.assign?.job_order_professionals?.length)
                        setProfessionalIds(props.assign?.job_order_professionals?.map(item => item?.professionalId))
                }
            }}
        >
            <DialogTitle>
                <Typography variant="h6" className="font-weight-bolder">Job Create</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="body2" className="mb-1">
                            Name
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={params?.name || ""}
                            name="name"
                            onChange={InputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="body2" className="mb-1">
                            Mobile Number
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            // fullWidth
                            type="number"
                            // value={params?.check_mobile || ""}
                            name="check_mobile"
                            onChange={InputChange}
                            defaultValue={params?.mobile}
                            FormHelperTextProps={{
                                style: {
                                    color: (type == "error") ? "red" : "green",
                                    display: (type == null) ? "none" : "flex"
                                }
                            }}
                            helperText={(type === "error") ? "Customer Not Exist" : "Customer Exist"}
                            // placeholder="Enter Mobile Number..."
                        />

                    </Grid>
                    <Grid item xs={12} sm={2}>
                        {showField === "success" ?
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                className={classes.gridItem}
                                onClick={Check}>
                                Check
                            </Button>
                            :
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                className={classes.gridItem}
                                disabled={disabled}
                                onClick={SendOTP}>
                                send OTP
                            </Button>
                        }
                    </Grid>
                    {
                        showOTPField ?
                            <>
                                <Grid item xs={12} sm={2}>
                                    <Typography variant="body2" className="mb-1">
                                        OTP
                                    </Typography>
                                    <TextField
                                        size="small"
                                        name="otp"
                                        fullWidth
                                        value={params?.otp || ""}
                                        onChange={InputChange}
                                        variant="outlined"/>
                                </Grid>
                                <Grid item xs={12} sm={2} className={classes.gridItem}>
                                    <Button size="small" variant="contained" color="secondary" onClick={VerifyOTP}>Verify
                                        OTP </Button>
                                </Grid>
                            </> : null
                    }
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" className="mb-1">
                            WhatsApp number
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={params?.whatsAppNo || ""}
                            name="whatsAppNo"
                            onChange={InputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" className="mb-1">
                            Email
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={params?.email || ""}
                            name="email"
                            onChange={InputChange}
                        />
                    </Grid>
                    <Grid container item xs={12} sm={6} spacing='1'>

                        <Grid item xs={10} sm={9}>

                            <Typography variant="body2" className="mb-1">
                                Address
                            </Typography>
                            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                                <Select
                                    name="addressId"
                                    // value={order?.Address}
                                    // onChange={InputChange}
                                    onChange={orderDetails}
                                >
                                    {
                                        params?.customerAddress?.map((item) =>
                                            <MenuItem
                                                value={item.id}>{item.address + "," + item.pincode + "," + item.city}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} sm={2}>
                            <Button variant="contained" color="secondary" onClick={handleOpenDialog}
                                    className={classes.gridItem}>
                                Add
                            </Button>
                        </Grid>

                    </Grid>


                    <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" maxWidth="xs"
                            fullWidth={true}>
                        <DialogTitle id="form-dialog-title">Add Address</DialogTitle>
                        <DialogContent justifyContent="center">
                            <Grid container justifyContent="center">
                                <Grid item xs={12} sm={10}>
                                    <Typography variant="body2" className="mb-1">
                                        Nick Name
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        name="nickName"
                                        onChange={onUserInputAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Typography variant="body2" className="mb-1">
                                        Landmark
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        // value={params?.customerAddress?.landmark || ""}
                                        name="landmark"
                                        onChange={onUserInputAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Typography variant="body2" className="mb-1">
                                        Pincode
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        // value={params?.customerAddress?.pincode || ""}
                                        name="pincode"
                                        onChange={onUserInputAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Typography variant="body2" className="mb-1">
                                        City
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        // value={params?.customerAddress?.city || ""}
                                        name="city"
                                        onChange={onUserInputAddress}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={10}>
                                    <Typography variant="body2" className="mb-1">
                                        State
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        // value={params?.customerAddress?.state || ""}
                                        name="state"
                                        onChange={onUserInputAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Typography variant="body2" className="mb-1">
                                        Address
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        name="address"
                                        onChange={onUserInputAddress}
                                    />
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} variant="contained" color="primary">
                                Cancel
                            </Button>
                            <Button variant="contained" color="secondary" onClick={addAddress}>
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <Typography variant="body2" className="mb-1">*/}
                    {/*        Landmark*/}
                    {/*    </Typography>*/}
                    {/*    <TextField*/}
                    {/*        variant="outlined"*/}
                    {/*        size="small"*/}
                    {/*        fullWidth*/}
                    {/*        value={params?.customerAddress?.landmark || ""}*/}
                    {/*        name="landmark"*/}
                    {/*        onChange={InputChange}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <Typography variant="body2" className="mb-1">*/}
                    {/*        Pincode*/}
                    {/*    </Typography>*/}
                    {/*    <TextField*/}
                    {/*        variant="outlined"*/}
                    {/*        size="small"*/}
                    {/*        fullWidth*/}
                    {/*        value={params?.customerAddress?.pincode || ""}*/}
                    {/*        name="pincode"*/}
                    {/*        onChange={InputChange}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <Typography variant="body2" className="mb-1">*/}
                    {/*        City*/}
                    {/*    </Typography>*/}
                    {/*    <TextField*/}
                    {/*        variant="outlined"*/}
                    {/*        size="small"*/}
                    {/*        fullWidth*/}
                    {/*        value={params?.customerAddress?.city || ""}*/}
                    {/*        name="city"*/}
                    {/*        onChange={InputChange}*/}
                    {/*    />*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <Typography variant="body2" className="mb-1">*/}
                    {/*        State*/}
                    {/*    </Typography>*/}
                    {/*    <TextField*/}
                    {/*        variant="outlined"*/}
                    {/*        size="small"*/}
                    {/*        fullWidth*/}
                    {/*        value={params?.customerAddress?.state || ""}*/}
                    {/*        name="state"*/}
                    {/*        onChange={InputChange}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" className="mb-1">
                            Schedule At
                        </Typography>
                        <TextField
                            style={{
                                width: "100%",
                            }}
                            InputLabelProps={{shrink: true}}
                            variant="outlined"
                            name="scheduledAt"
                            id="datetime-local"
                            size="small"
                            type="date"
                            format={"MM/DD/YYYY"}
                            value={order?.scheduledAt || ""}

                            onChange={orderDetails}
                            // onChange={(e)=>{ console.log(e.target.value)}}

                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" className="mb-1">
                            Start Time
                        </Typography>
                        <TextField
                            InputLabelProps={{shrink: true}}
                            variant="outlined"
                            type="time"
                            size="small"
                            name="startTime"
                            fullWidth
                            value={order?.startTime || ""}
                            onChange={orderDetails}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" className="mb-1">
                            End Time
                        </Typography>
                        <TextField
                            InputLabelProps={{shrink: true}}
                            variant="outlined"
                            type="time"
                            size="small"
                            fullWidth
                            value={order?.endTime || ""}
                            name="endTime"
                            onChange={orderDetails}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" className="mb-1">
                            Category
                        </Typography>
                        <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                            <Select
                                // native
                                value={order?.category}
                                onChange={orderDetails}
                                // inputProps={{
                                //     name: 'age',
                                //     id: 'outlined-age-native-simple',
                                // }}
                                name="categoryId"
                            >
                                {
                                    categories.map((item) => {
                                        return (
                                            <MenuItem value={item.id}>{item.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" className="mb-1">
                            Sub Category
                        </Typography>
                        <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                            <Select
                                // native
                                name="subCategoryId"
                                value={order?.subCategory}
                                onChange={orderDetails}
                            >
                                {
                                    subCategories?.map((item) => {
                                        return (

                                            <MenuItem value={item.id}>{item["name"]}</MenuItem>
                                        )

                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <Typography variant="body2" className="mb-1">*/}
                    {/*        Price*/}
                    {/*    </Typography>*/}
                    {/*    <TextField*/}
                    {/*        variant="outlined"*/}
                    {/*        size="small"*/}
                    {/*        fullWidth*/}
                    {/*        value={order?.price || ""}*/}
                    {/*        name="price"*/}
                    {/*        onChange={orderDetails}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <Typography variant="body2" className="mb-1">*/}
                    {/*        Market Price*/}
                    {/*    </Typography>*/}
                    {/*    <TextField*/}
                    {/*        variant="outlined"*/}
                    {/*        size="small"*/}
                    {/*        fullWidth*/}
                    {/*        value={order?.marketPrice || ""}*/}
                    {/*        name="marketPrice"*/}
                    {/*        onChange={orderDetails}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <Typography variant="body2" className="mb-1">*/}
                    {/*        Actual Price*/}
                    {/*    </Typography>*/}
                    {/*    <TextField*/}
                    {/*        variant="outlined"*/}
                    {/*        size="small"*/}
                    {/*        fullWidth*/}
                    {/*        value={order?.actualPrice || ""}*/}
                    {/*        name="actualPrice"*/}
                    {/*        onChange={orderDetails}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" className="mb-1">
                            Services
                        </Typography>
                        <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                            <Select

                                name="serviceId"
                                // value={order?.services}
                                onChange={orderDetails}
                            >
                                {
                                    services.map((item) => {
                                        return (
                                            <MenuItem value={item.id}>{item.title}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" className="mb-1">
                            Payment type
                        </Typography>
                        <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                            <Select
                                // native
                                // value={order?.paymentType}
                                onChange={orderDetails}
                                // inputProps={{
                                //     name: 'age',
                                //     id: 'outlined-age-native-simple',
                                // }}
                                name={"paymentType"}
                            >
                                <MenuItem value={"online"}>Online</MenuItem>
                                <MenuItem value={"cash"}>Cash</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>


                </Grid>
                <Divider/>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={() => {
                        // setParams({})
                        // props.onClose()
                        // setProfessionalIds([]);
                        updateUser()
                    }}
                    style={{textTransform: "none"}}
                >
                    Update User
                </Button>
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                        setParams({})
                        props.onClose()
                        setProfessionalIds([]);
                    }}
                    style={{textTransform: "none"}}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={createOrder}
                    style={{textTransform: "none"}}
                >
                    {loading ? "Sending..." : "Send"}
                </Button>
            </DialogActions>
        </Dialog>
    </Fragment>);
}
