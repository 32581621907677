import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCze3y7Hq0bi2gjcIC_tiNE8A3B-_7_HNc",
    authDomain: "professional-technosters.firebaseapp.com",
    projectId: "professional-technosters",
    storageBucket: "professional-technosters.appspot.com",
    messagingSenderId: "683256266719",
    appId: "1:683256266719:web:6f9933c5b1a324981d8e6a",
};

export const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


export const getClientToken = (setTokenFound) => {
    return getToken(messaging, { vapidKey: 'BOvoImkHkt7_OGUqt1kWTvSnWFbLP8KPwj1C4LnVkDdi51lLRwz9Y2bKrNY-p5LzFe-IvWuJkUoAJRz3yPM39wE' }).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            setTokenFound(true);
            return currentToken;
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
