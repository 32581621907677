import React, {Fragment, useEffect, useState} from "react";
import {
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Container,
    Typography,
    TextField,
    Grid,
    MenuItem,
    FormControlLabel,
    IconButton, Switch, Tooltip,
} from "@material-ui/core";
import {Edit, Delete} from "@material-ui/icons";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import {crud} from "../services/CrudFactory";
import moment from "moment";
import AddButton from "../components/AddButton";
import {Pagination} from "pagination-ui";
import SkeletonUse from "../components/SkeletonUse";
import {Tr, Td} from "../components";
import ServiceDialog from "../dialog/ServiceDialog";
import {$user} from "../services/UserFactory";
import {CREATE_SERVICE, UPDATE_SERVICE, CHANGE_SERVICE_STATUS, DELETE_SERVICE} from "../constant";

export default function Service() {
    const [service, setService] = useState(null);
    const [services, setServices] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({fromDate: ""});
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const getServices = async () => {
        const {data: {services, total}} = await crud.get("retrieve/web/services", {
            ...filters,
            limit,
            page
        });
        setServices(services);
        setLoading(false);
        setTotal(total);
    }
    const getCategories = async () => {
        const {data: {categories}} = await crud.get("retrieve/web/categories");
        setCategories(categories);
    }

    const getSubCategories = async (categoryId) => {
        const {data: {sub_categories}} = await crud.get("retrieve/web/sub-categories", {
            categoryId
        });
        setSubCategories(sub_categories);
    }


    useEffect(() => {
        getCategories()
    }, []);
    useEffect(() => {
        getServices();
    }, [filters, limit, page]);

    const setFilter = (key, value) => {
        setFilters(prev => {
            return {
                ...prev,
                [key]: value === "any" ? "" : value
            };
        });
    };

    useEffect(() => {
        const new_errors = {dateFrom: ""};
        if (moment(filters.from_date).isAfter(filters.to_date))
            new_errors.dateFrom = "From Date should be less than Date To";
        setErrors(prev => {
            return {...prev, ...new_errors}
        });
        if (new_errors.dateFrom) {
            setSubCategories([]);
            return;
        }
    }, [page, limit, filters]);
    useEffect(() => {
        setLoading(true);
    }, []);
    return (
        <Fragment>
            <Appbar/>
            <Drawer/>

            <Container maxWidth="xl" style={{marginTop: "20px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={2}>
                        <Typography variant="h6" className="font-weight-bolder">
                            Services
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            select
                            fullWidth
                            size="small"
                            variant="outlined"
                            label="Select Category"
                            name="status"
                            onChange={(e) => {
                                setFilter("categoryId", e.target.value);
                                getSubCategories(e.target.value);

                            }}
                        >
                            <MenuItem value="any">All</MenuItem>
                            {
                                categories.map((category => <MenuItem value={category?.id}>{category?.name}</MenuItem>))
                            }

                        </TextField>
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            select
                            fullWidth
                            size="small"
                            variant="outlined"
                            label="Select SubCategory"
                            name="status"
                            onChange={(e) => {
                                setFilter("subCategoryId", e.target.value);
                            }}
                        >
                            <MenuItem value="any">All</MenuItem>
                            {
                                subCategories.map((subCategory => <MenuItem
                                    value={subCategory?.id}>{subCategory?.name}</MenuItem>))
                            }

                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Tooltip title="From date">
                            <TextField
                                style={{
                                    width: "100%",
                                }}
                                variant="outlined"
                                id="datetime-local"
                                error={!!errors.dateFrom}
                                helperText={errors.dateFrom}
                                label="From Date"
                                InputLabelProps={{shrink: true}}
                                size="small"
                                type="date"
                                format={"DD/MM/YYYY"}
                                onChange={(e) => setFilter("from_date", e.target.value)}
                            />
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                        <Tooltip title="To date">
                            <TextField
                                style={{
                                    width: "100%",
                                }}
                                variant="outlined"
                                id="datetime-local"
                                size="small"
                                label="To Date"
                                InputLabelProps={{shrink: true}}
                                type="date"
                                format={"DD/MM/YYYY"}
                                onChange={(e) => setFilter("to_date", e.target.value)}
                            />
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            select
                            fullWidth
                            size="small"
                            variant="outlined"
                            label="Status"
                            name="status"
                            onChange={(e) => setFilter("status", e.target.value)}
                        >
                            <MenuItem value="any">All</MenuItem>
                            <MenuItem value="1">Active</MenuItem>
                            <MenuItem value="0">Inactive</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>

                <Paper className="mt-3">
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Category Name</TableCell>
                                    <TableCell>SubCategory Name</TableCell>
                                    <TableCell>Service Name</TableCell>
                                    <TableCell>Actual Price</TableCell>
                                    <TableCell>Market Price</TableCell>
                                    <TableCell>Create At</TableCell>
                                    <TableCell>Create By</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell className="text-center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    !loading ?
                                        services.map((item, i) => (
                                            <>
                                                <Tr hover key={i}>
                                                    <Td>
                                                        {item.category?.name}
                                                    </Td>
                                                    <Td>{item?.sub_category?.name}</Td>
                                                    <Td>{item?.title}</Td>
                                                    <Td>{item?.actualPrice}</Td>
                                                    <Td>{item?.marketPrice}</Td>
                                                    <Td>{moment(item.createdAt).format(crud.dateFormat)}</Td>
                                                    <Td>{item.created_by?.name}</Td>
                                                    <Td>
                                                    <span style={{
                                                        background: `${item.status ? "#5fdba7" : "#F96161"}`,
                                                        padding: "5px 10px",
                                                        borderRadius: "10px",
                                                        fontSize: "12px",
                                                        color: "#FFFFFF"
                                                    }}>
                                                        {item.status ? "Active" : "Inactive"}</span>
                                                    </Td>
                                                    <TableCell className="text-center d-flex align-items-center">
                                                        {
                                                            $user.hasPermission(UPDATE_SERVICE) &&
                                                            <Tooltip title="Edit">
                                                                <IconButton size="small"
                                                                            onClick={() => setService(item)}>
                                                                    <Edit color="primary"/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        {
                                                            $user.hasPermission(DELETE_SERVICE) &&
                                                            <Tooltip title="Delete">
                                                                <IconButton size="small" onClick={async () => {
                                                                    await crud.confirm({
                                                                        textContent: 'Do you want to delete the Service ?'
                                                                    });
                                                                    await crud.post("delete/web/service", {id: item?.id});
                                                                    getServices();
                                                                }}>
                                                                    <Delete color="primary"/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        }

                                                        {
                                                            $user.hasPermission(CHANGE_SERVICE_STATUS) &&
                                                            <Tooltip title="Change Status">
                                                                <IconButton size="small">

                                                                    <FormControlLabel
                                                                        className="ml-1"
                                                                        control={
                                                                            <Switch color="primary" size="small"
                                                                                    checked={item.status}
                                                                                    onChange={async (e) => {
                                                                                        await crud.post("status/web/service", {
                                                                                            id: item.id,
                                                                                        });
                                                                                        getServices();
                                                                                    }}
                                                                            />
                                                                        }
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </TableCell>
                                                </Tr>

                                            </>
                                        )) : <SkeletonUse cellCount={8} limit={10}/>}
                            </TableBody>
                            {
                                !services.length && !loading &&
                                <Typography className="p-2 w-100 text-center font-weight-bold">
                                    No records found
                                </Typography>

                            }
                        </Table>

                        <Pagination
                            limit={limit}
                            limitRange
                            total={total}
                            page={page}
                            onPageChange={setPage}
                            onLimitChange={setLimit}
                        />
                    </TableContainer>
                </Paper>
            </Container>
            {
                $user.hasPermission(CREATE_SERVICE) && <AddButton handleClickOpen={() => setService({})}/>
            }
            {/*<SubCategoryDialog open={!!subCategory}*/}
            {/*                   onClose={() => setSubCategory(null)} subCategory={subCategory}*/}
            {/*                   subCategories={getSubCategories}/>*/}

            <ServiceDialog open={!!service}
                           close={() => setService(null)}
                           service={service}
                           getServices={getServices}
            />
            <Footer/>
        </Fragment>
    );
}
