import React, {Fragment, useEffect, useState} from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Container,
    Typography,
    TextField,
    Grid,
    MenuItem,
    FormControlLabel,
    IconButton,
    Switch,
    Tooltip,
    Select,
    Button,
    FormControl,
    Avatar,
    Chip,
} from "@material-ui/core";
import {List} from "@material-ui/icons";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import {crud} from "../services/CrudFactory";
import moment from "moment";
import AddButton from "../components/AddButton";
import {Pagination} from "pagination-ui";
import SkeletonUse from "../components/SkeletonUse";
import {Tr, Td} from "../components";
import {$user} from "../services/UserFactory";
import {
    CREATE_SERVICE,
    UPDATE_SERVICE,
    CHANGE_SERVICE_STATUS,
    DELETE_SERVICE,
    CHANGE_PROFESSIONAL_STATUS,
} from "../constant";
import TableDataCom from "../components/TableDataCom";
import DefaultUserImage from "../images/userDefaultImage.jpg";
import _ from "lodash";
import PaymentDetailsDialog from "../dialog/PaymentDetailsDialog";
import ImagePreview from "../components/ImagePreview";
import PaymentReceived from "../dialog/PaymentReceived";

export default function PaymentRequest() {
    const [state, setState] = useState({
        onSave: null,
    });
    const [professionals, setProfessionals] = useState([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [total, setTotal] = useState(0);
    const [professionalImage, setProfessionalImage] = useState(null);
    const [paymentId, setPaymentId] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    console.log(paymentStatus, "---------paymentStatus");

    const getProfessionalUsers = async ({page, limit, filters, search}) => {
        const {
            data: {paymentRequests},
        } = await crud.get("retrieve/web/payment-requests", {
            page,
            limit,
            search,
            ...filters,
        });
        setProfessionals(paymentRequests);
        setPaymentStatus(paymentRequests.status)
        setTotal(total);
    };

    const handlePaymentStatus = async (e, qrId) => {
        setPaymentStatus(e.target.value);
        console.log(qrId, "--------qrId");
        const result = await crud.post("update/web/payment-request", {qrId})
        console.log(result, "----------result");
    }

    useEffect(() => {
        getProfessionalUsers();
    }, []);

    const tableHeaders = [
        "Profile Photo",
        "Name",
        "Mobile",
        "Due Amount",
        "Create At",
        "Status",
    ];
    console.log(professionals)
    return (
        <React.Fragment>
            <Appbar/>
            <Drawer/>

            <TableDataCom
                from_date
                to_date
                search
                title={"Professional User"}
                headers={tableHeaders}
                data={professionals}
                getData={getProfessionalUsers}
                getRow={(professional) => {
                    const tableCells = [
                        <Avatar
                            style={{
                                border: "1px solid grey",
                                width: "37px",
                                height: "37px",
                                cursor: "pointer",
                            }}
                            src={
                                !_.isEmpty(professional.profilePicture)
                                    ? crud.baseUrl2 +
                                    "professional-profiles/" +
                                    professional.profilePicture
                                    : DefaultUserImage
                            }
                            onClick={() => setProfessionalImage(professional)}
                        />,
                        professional.professional.name,
                        professional.professional.mobile,
                        professional.totalAmount || "NA",

                        moment(professional.createdAt).format(crud.dateFormat),
                        <FormControl fullWidth disabled={professional.status == "received"}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={paymentStatus}
                                label="Age"
                                defaultValue={professional.status}
                                onChange={(e) => {
                                    handlePaymentStatus(e, professional.qrId)
                                }}
                            >
                                <MenuItem value={"active"}>Pending</MenuItem>
                                <MenuItem value={"received"}>Received</MenuItem>
                            </Select>
                        </FormControl>
                    ];
                    return {
                        id: professional.id,
                        cells: tableCells,
                        actions: (getData) => (
                            <>
                                <Button variant="contained" onClick={() => {
                                    setPaymentId(professional)
                                    setDialogVisible(true)
                                }}>check</Button>
                            </>
                        ),
                    };

                }}
                switchRow={(professional) =>
                    new Promise((resolve) => {
                        setState((prev) => ({
                            ...prev,
                            onSave: resolve,
                        }));
                        setProfessionals(professional);
                    })
                }
                total={total}
            />
            <PaymentReceived
                open={dialogVisible}
                id={paymentId}
                onClose={() => setDialogVisible(false)}
            />
            <ImagePreview
                open={!!professionalImage}
                close={() => setProfessionalImage(null)}
                imageUrl={professionalImage?.profilePicture}
                title={"Professional Image"}
                imageSize={"100%"}
                path={"/professional-profiles/"}
                defaultImage={DefaultUserImage}
            />
            <Footer/>
        </React.Fragment>
    );
}
