import React, {Fragment, useEffect, useState} from "react";
import Routing from "./router/Routing";
import "./App.css";
import {MuiThemeProvider, createTheme} from "@material-ui/core/styles";
import {onMessageListener} from "./services/firebase";
// use default theme
// const theme = createMuiTheme();

// Or Create your Own theme:
const theme = createTheme({
    palette: {
        primary: {
            main: "#115ECE",
            // main: "#a79292",
        },
        secondary: {
            main: "#5189CF",
        },
        background: {
            primary: "#115ECE"
        }
    },

});


function App() {

    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({title: '', body: ''});

    useEffect(() => {
        onMessageListener().then(payload => {
            setShow(true);
            setNotification({title: payload.notification.title, body: payload.notification.body})
            console.log(payload);
        }).catch(err => console.log('failed on message listener-----------------------------: ', err));
    }, []);
    return (
        <Fragment>
            <MuiThemeProvider theme={theme}>
                <Routing/>
            </MuiThemeProvider>
        </Fragment>
    );
}

export default App;
