import React, {Fragment, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Divider,
    TextField,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextareaAutosize,
    Select,
    ListItemText,
    IconButton,
    FormGroup,
    Checkbox,
    MenuItem,
    CardActionArea,
    CardMedia,
    Card
} from "@material-ui/core";
import {Add, CloudUpload, ExpandMore} from "@material-ui/icons";
import {CloseIcon} from "react-modal-image/lib/icons";
import {crud} from "../services/CrudFactory";
import moment from "moment";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    fieldtitle: {
        textTransform: "uppercase",
    }, textareaAutosize: {
        width: "100%", height: "100px !important", padding: "10px", borderRadius: "10px",
    }, input: {
        display: "none",
    },
}));

export default function PanelUsersDialog(props) {
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [permissionIds, setPermissionIds] = useState([]);
    const [params, setParams] = useState({});
    const [saving, setSaving] = useState(false);

    const [data, setData] = useState([
        {image: ""}
    ]);


    const save = async () => {
        const IfscRegex = /^[A-Za-z]{4}\d{7}$/;
        const AccountNuRegex = /^\d{9,18}$/;
        const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
        const mobileRegex = /^[0]?[6789]\d{9}$/;
        const NameRegex = /^[A-Za-z]/;

        let error = [];
        if (params.name && !NameRegex.test(params.name)) {
            error.push("Valid Name Is Required");
        } else if (params.email && !emailRegex.test(params.email)) {
            error.push("Valid Email Is Required");
        } else if (params.mobile && !mobileRegex.test(params.mobile)) {
            error.push("Valid Mobile number Is Required");
        } else if (params.allot_mobile_no && !mobileRegex.test(params.allot_mobile_no)) {
            error.push("Valid Mobile number Is Required");
        } else if (params.allot_mobile_no && !mobileRegex.test(params.allot_mobile_no)) {
            error.push("Valid Mobile number Is Required");
        } else if (params.whats_app && !mobileRegex.test(params.whats_app)) {
            error.push("Valid Mobile number Is Required");
        } else if (params.bank_account_no && !AccountNuRegex.test(params.bank_account_no)) {
            error.push("Valid Bank Account number Is Required");
        } else if (params.bank_ifsc && !IfscRegex.test(params.bank_ifsc)) {
            error.push("Valid Bank Ifsc Code Is Required");
        }

        if (!_.isEmpty(error)) {
            await crud.notify({
                message: error, type: "error",
            });
        } else {
            try {
                setSaving(true);
                const formData = new FormData();
                let pIds = permissionIds.map(p => permissions.find(s => s.name === p).id);
                Object.assign(params, {permissionsIds: pIds})
                Object.keys(params).forEach(key => formData.append(key, params[key]));
                data.map((item, i) => {
                    formData.append("staffDocuments", item.image ?? "")
                });

                await crud.create(params?.id ? "update/web/staff" : "create/web/staff", formData);
                cancel();
                props.onSave();
            } finally {
                setSaving(false);
            }
        }
    };

    useEffect(() => {
        console.log(data, '---use effect data iamge-------')
    }, [data]);

    const cancel = () => {
        props.close();
        setParams({});
        setPermissionIds([]);
        setData([{staffDocument: ''}]);
        // setPermission_ids([]);
    };


    const getPermissions = async () => {
        const {
            data: {permissions},
        } = await crud.get("retrieve/web/permissions");
        setPermissions(permissions);
    };


    const InputChange = (e) => {
        const {value, name} = e.target;
        setParams(prevState => {
            return {...prevState, [name]: value}
        });
    }

    const getRoles = async () => {
        const {data: {roles}} = await crud.retrieve("retrieve/web/roles");
        setRoles(roles);
    }

    useEffect(() => {
        getRoles();
    }, []);

    useEffect(() => {
        console.log(data, '-------data------')
    }, [data]);

    const classes = useStyles();
    return (<Fragment>
        <Dialog
            onClose={props.close}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            maxWidth={"lg"}
            TransitionProps={{
                onEnter: () => {
                    if (props.agent?.id) {
                        setParams(props.agent);
                        setPermissionIds(props.agent?.permissions.map(item => item?.name));
                        setData(props.agent?.staff_documents);
                    }

                    getPermissions();
                }
            }}
        >
            <DialogTitle>
                <Typography variant="h5">Staff</Typography>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            Name <span className="text-danger">*</span>
                        </Typography>
                        <TextField variant="outlined" size="small" fullWidth name="name" value={params.name || ""}
                                   onChange={InputChange}/>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            UserName <span className="text-danger">*</span>
                        </Typography>
                        <TextField variant="outlined" size="small" fullWidth name="userName"
                                   value={params.userName || ""}
                                   onChange={InputChange}/>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            Father's Name <span className="text-danger">*</span>
                        </Typography>
                        <TextField variant="outlined" size="small" fullWidth name="fatherName"
                                   value={params.fatherName || ""}
                                   onChange={InputChange}/>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            Email <span className="text-danger">*</span>
                        </Typography>
                        <TextField variant="outlined" size="small" fullWidth name="email" value={params.email || ""}
                                   onChange={InputChange}/>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            Mobile <span className="text-danger">*</span>
                        </Typography>
                        <TextField variant="outlined" size="small" fullWidth name="mobile" inputProps={{maxLength: 10}}
                                   value={params.mobile || ""}
                                   onChange={InputChange}/>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            Allot Mobile No. <span className="text-danger">*</span>
                        </Typography>
                        <TextField variant="outlined" size="small" fullWidth name="alterMobileNo"
                                   inputProps={{maxLength: 10}}
                                   value={params.alterMobileNo || ""}
                                   onChange={InputChange}/>
                    </Grid>
                    {params.id ? "" : <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            Password <span className="text-danger">*</span>
                        </Typography>
                        <TextField variant="outlined" size="small" fullWidth name="password" type="password"
                            // value={params.password || ""}
                                   onChange={InputChange}
                        />
                    </Grid>}

                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            WhatsApp No
                        </Typography>
                        <TextField variant="outlined" size="small" fullWidth name="whatsappNo"
                                   inputProps={{maxLength: 10}}
                                   value={params.whatsappNo || ""}
                                   onChange={InputChange}/>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            Work Experience <span className="text-danger">*</span>
                        </Typography>
                        <TextField variant="outlined" size="small" fullWidth name="workExperience" type="number"
                                   value={params.workExperience || ""}
                                   onChange={InputChange}/>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            Post Name
                        </Typography>
                        <TextField variant="outlined" size="small" fullWidth name="postName"
                                   value={params.postName || ""}
                                   onChange={InputChange}/>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            Qualification
                        </Typography>
                        <TextField variant="outlined" size="small" fullWidth name="qualification"
                                   value={params?.qualification || ""}
                                   onChange={InputChange}/>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            DOB <span className="text-danger">*</span>
                        </Typography>
                        <TextField
                            type="date"
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="dob"
                            onChange={InputChange}
                            value={moment(params.dob || "").format("YYYY-MM-DD")}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            Date of Joining <span className="text-danger">*</span>
                        </Typography>
                        <TextField
                            type="date"
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="dateOfJoining"
                            value={moment(params.dateOfJoining || "").format("YYYY-MM-DD") || ""}
                            onChange={InputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            Role <span className="text-danger">*</span>
                        </Typography>
                        <TextField
                            select
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="role"
                            SelectProps={{
                                native: true,
                            }}
                            onChange={InputChange}
                            className="mb-3"
                            value={params.role || ""}
                        >
                            <option value="">Select Role</option>
                            {roles.map((item) => (<option value={item.id}>{item.name}</option>))}

                        </TextField>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            marital Status <span className="text-danger">*</span>
                        </Typography>
                        <TextField
                            select
                            fullWidth
                            size="small"
                            variant="outlined"
                            SelectProps={{
                                native: true,
                            }}
                            value={params.martialStatus || ""}
                            name="martialStatus"
                            onChange={InputChange}
                        >
                            <option value="">Select</option>
                            <option value="1">Married</option>
                            <option value="2">Unmarried</option>
                        </TextField>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            Gender <span className="text-danger">*</span>
                        </Typography>

                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                name="position"
                                defaultValue="top"
                            >
                                <FormControlLabel
                                    value={"male"}
                                    control={<Radio color="primary"/>}
                                    label="Male"
                                    checked={params?.gender === "male"}
                                    className="ml-1"
                                    name="gender"
                                    onChange={InputChange}
                                />
                                <FormControlLabel
                                    value={"female"}
                                    checked={params?.gender === "female"}
                                    control={<Radio color="primary"/>}
                                    label="Female"
                                    name="gender"
                                    onChange={InputChange}
                                />
                                <FormControlLabel
                                    value={"others"}
                                    checked={params?.gender === "others"}
                                    control={<Radio color="primary"/>}
                                    label="Others"
                                    name="gender"
                                    onChange={InputChange}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.fieldtitle}
                                >
                                    A/c
                                </Typography>
                                <TextField fullWidth variant="outlined" size="small" name="accountNumber"
                                           value={params.accountNumber || ""}
                                           onChange={InputChange}/>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.fieldtitle}
                                >
                                    Ifsc
                                </Typography>
                                <TextField fullWidth variant="outlined" size="small" name="ifsc"
                                           onChange={InputChange} value={params.ifsc || ""}/>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.fieldtitle}
                                >
                                    Bank Name
                                </Typography>
                                <TextField fullWidth variant="outlined" size="small" name="bankName"
                                           onChange={InputChange} value={params.bankName || ""}/>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.fieldtitle}
                                >
                                    Branch Name
                                </Typography>
                                <TextField fullWidth variant="outlined" size="small" name="branchName"
                                           onChange={InputChange}
                                           value={params.branchName || ""}/>
                            </Grid>
                        </Grid>

                    </Grid>

                    {/*staff document*/}
                    <Grid item xs={12}>
                        <IconButton
                            style={{
                                backgroundColor: "#115ECE", color: "#fff"
                            }}
                        >
                            <Add/>
                        </IconButton>
                        <Typography
                            className="ml-2" onClick={() => {
                            setData(prev => {
                                return [...prev, {image: ""}]
                            })
                        }} style={{cursor: "pointer"}} variant="body">Add
                            Document</Typography>
                    </Grid>

                    {
                        data?.map((item, index) => <Grid item md={3}>
                            {
                                item?.image?.type ? <>
                                    <div className="image-box" style={{position: 'relative'}}>
                                        <div className="delete-box" onClick={() => {
                                            setData(prev => {
                                                const temp = [...prev];
                                                return temp.filter((s, i) => i !== index);
                                            })
                                        }}>
                                            <DeleteIcon/>
                                        </div>
                                        <label onChange={(e) => {
                                            setData(prev => {
                                                const temp = [...prev];
                                                const exist = temp.find((s, i) => i === index);
                                                if (!!exist) {
                                                    exist.image = e.target.files[0];
                                                    return temp;
                                                }
                                            });

                                        }} className="d-flex justify-content-center align-items-center image-box">
                                            <input id="staff_image" type="file" style={{display: "none"}}/>

                                            <img className="image-box" src={window.URL.createObjectURL(item?.image)}/>
                                        </label>
                                    </div>
                                </> : item.image ?
                                    <>
                                        <div className="image-box" style={{position: "relative"}}>
                                            <div className="delete-box" onClick={ async ()=>{
                                                await crud.confirm({
                                                   textContent:"Do you want to delete the document Image?"
                                                });
                                               const {data:{all_image}} = await crud.post("delete/web/staff-document",{id:item?.id})
                                               setData(all_image);
                                            }} >
                                                <DeleteIcon/>
                                            </div>
                                            <label  className="d-flex justify-content-center align-items-center image-box">
                                                {/*<input id="staff_image" type="file" style={{display: "none"}}/>*/}

                                                <img className="image-box"
                                                     src={`${crud.baseUrl2}/staff-documents/${item.image}`}/>
                                            </label>
                                        </div>
                                    </>

                                    :
                                    <>
                                        <div className="image-box" style={{position: "relative"}}>
                                            <div className="delete-box" onClick={() => {
                                                setData(prev => {
                                                    const temp = [...prev];
                                                    return temp.filter((s, i) => i !== index);
                                                })
                                            }}>
                                                <DeleteIcon/>
                                            </div>
                                            <label onChange={(e) => {
                                                setData(prev => {
                                                    const temp = [...prev];
                                                    const exist = temp.find((s, i) => i === index);
                                                    if (!!exist) {
                                                        exist.image = e.target.files[0];
                                                        return temp;
                                                    }
                                                });
                                            }} className="d-flex justify-content-center align-items-center image-box">

                                                <input id="staff_image" type="file" style={{display: "none"}}/>

                                                <div className="d-flex flex-column align-items-center">
                                                    <CloudUploadIcon style={{fontSize: "40px"}}/>
                                                    <Typography style={{fontSize: "13px", color: "black"}}
                                                                className="font-weight-bold">Upload
                                                        Image</Typography>
                                                </div>
                                            </label>
                                        </div>
                                    </>
                            }
                        </Grid>)
                    }
                </Grid>
                <Grid item md={12} spacing={2} container>
                    <Grid item xs={12} md={6} sm={6}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            Address <span className="text-danger">*</span>
                        </Typography>
                        <TextareaAutosize
                            maxRows={4}
                            color="secondary"
                            aria-label="maximum height"
                            placeholder="Address..."
                            className={classes.textareaAutosize}
                            name="address"
                            value={params.address || ""}
                            onChange={InputChange}
                        />
                    </Grid>
                    {/*permission code */}
                    <Grid item xs={12} md={6} sm={6}>
                        <Typography variant="subtitle2" className={classes.fieldtitle}>
                            Permission <span className="text-danger">*</span>
                        </Typography>
                        <Select
                            multiple
                            labelId="permissions"
                            margin="dense"
                            onChange={(e) => setPermissionIds(e.target.value)}
                            value={permissionIds || []}
                            renderValue={permissions => permissions.map(e => e).join(", ")}
                            size="small" variant="outlined" fullWidth>
                            {
                                permissions.map(permission => <MenuItem value={permission?.name} key={permission.id}>
                                    <Checkbox checked={permissionIds.includes(permission?.name)}/>
                                    <ListItemText primary={permission?.name}/>
                                </MenuItem>)
                            }

                        </Select>
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => cancel()}
                    color="primary"
                    size="small"
                    style={{textTransform: "none"}}
                >
                    No thanks
                </Button>

                <Button
                    variant="contained"
                    disabled={saving}
                    onClick={() => save()}
                    color="primary"
                    size="small"
                    type="submit"
                    style={{textTransform: "none"}}
                >
                    {
                        !params?.id ? (saving ? "Creating..." : "Create") : (saving ? "Updating..." : "Update")
                    }
                </Button>
            </DialogActions>
        </Dialog>
    </Fragment>);
}
