import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Divider,
    TextField,
    MenuItem, Slide,
} from "@material-ui/core";
import {crud} from "../services/CrudFactory";

export default function ServiceDialog(props) {
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(false);

    const getCategories = async () => {
        const {data: {categories}} = await crud.get("retrieve/web/categories");
        setCategories(categories);
    }

    const getSubCategories = async (categoryId) => {
        const {data: {sub_categories}} = await crud.get("retrieve/web/sub-categories", {
            categoryId
        });
        setSubCategories(sub_categories);
    }


    const InputChange = async (e) => {
        const {name, value} = e.target;
        setParams({...params, [name]: value});
    }

    const serviceSumbit = async () => {
        try {
            setLoading(true);
            await crud.post(params?.id ? "update/web/service" : "create/web/service", params);
            cancel();
            props.getServices();
        } finally {
            setLoading(false);
        }

    };
    const cancel = () => {
        props.close();
        setParams({});
        setSubCategories([]);
    };
    useEffect(() => {
        console.log(subCategories, 'sub')
    }, [subCategories]);
    useEffect(() => {
        getCategories();
    }, []);


    return (
        <div>
            <Dialog
                TransitionComponent={Slide}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                maxWidth={"md"}
                TransitionProps={
                    {
                        onEnter: () => {
                            setParams(props.service);
                            if (props.service.id) {
                                getSubCategories(props.service?.categoryId);
                            }
                        }
                    }
                }
            >
                <DialogTitle>
                    <Typography variant="h5">{params.id ? "Service Update" : "Service"}</Typography>
                </DialogTitle>
                <Divider/>
                <DialogContent className="mb-2">
                    <Grid container spacing={2}>
                        {!params.id && <>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2" className="mb-1">
                                    Choose Category
                                </Typography>
                                <TextField
                                    select
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label="Select Category"
                                    name="categoryId"
                                    onChange={(e) => {
                                        InputChange(e);
                                        getSubCategories(e.target.value);
                                    }}
                                    className="mb-3"
                                >
                                    {
                                        categories.map((item) => (
                                            <MenuItem value={item.id}>{item.name}</MenuItem>
                                        ))
                                    }

                                </TextField>
                            </Grid>
                        </>}


                        <Grid item xs={12} sm={6}>
                            <Typography variant="body2" className="mb-1">
                                Service Name
                            </Typography>
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={params.title || ""}
                                name="title"
                                onChange={InputChange}
                                placeholder="Enter Service Name..."
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="body2" className="mb-1">
                                Sub-title Name
                            </Typography>
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="subTitle"
                                value={params.subTitle || ""}
                                onChange={InputChange}
                                placeholder="Enter Sub-title Name..."
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="body2" className="mb-1">
                                Choose Sub-Category
                            </Typography>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                variant="outlined"
                                label="Select SubCategories"
                                name="subCategoryId"
                                value={params?.subCategoryId || ""}
                                onChange={InputChange}
                                className="mb-3"
                            >
                                {
                                    subCategories.map((item) => (
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                    ))
                                }
                                {
                                    !subCategories.length &&
                                    <Typography className="p-1 w-100 no-record-color text-center font-weight-bold">
                                        No subCategories found
                                    </Typography>
                                }

                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Typography variant="body2" className="mb-1">
                                Service Market Price
                            </Typography>
                            <TextField
                                min="0"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="number"
                                name="marketPrice"
                                value={params.marketPrice || ""}
                                onChange={InputChange}
                                placeholder="Enter Market Amount..."
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Typography variant="body2" className="mb-1">
                                Service Actual Price
                            </Typography>
                            <TextField
                                min="0"
                                variant="outlined"
                                size="small"
                                type="number"
                                fullWidth
                                name="actualPrice"
                                value={params.actualPrice || ""}
                                onChange={InputChange}
                                placeholder="Enter Actual Amount..."
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                                <Divider/>

                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => cancel()}
                        color="primary"
                        size="small"
                        style={{textTransform: "none"}}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        disabled={loading}
                        onClick={() => serviceSumbit()}
                        color="primary"
                        size="small"
                        type="submit"
                        style={{textTransform: "none"}}
                    >
                        {
                            !params?.id ? (loading ? "Creating..." : "Create") : (loading ? "Updating..." : "Update")
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
