import React, { Fragment } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PeopleIcon from "@material-ui/icons/People";
import logo from "../images/logo.png";
import { Dashboard } from "@material-ui/icons";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import InfoIcon from "@material-ui/icons/Info";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import CategoryIcon from "@material-ui/icons/Category";
import StorefrontIcon from "@material-ui/icons/Storefront";
import PaymentIcon from '@material-ui/icons/Payment';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import {
  VIEW_JOB,
  VIEW_KYC,
  VIEW_NOTIFICATIONS,
  VIEW_SUPPORT_TICKET,
  VIEW_CUSTOMERS,
  VIEW_PROFESSIONALS,
  VIEW_EMPLOYEES,
  VIEW_SLIDER_IMAGES,
  VIEW_CATEGORIES,
  VIEW_SERVICES,
  VIEW_FAQ,
  VIEW_ABOUT,
  VIEW_DASHBOARD,
  VIEW_STATISTICS
} from "../constant";
import { $user } from "../services/UserFactory";

export default function Drawer() {
  return (
    <Fragment>
      <Box className="main__root" boxShadow={3}>
        <List color="primary" id="style-4">
          <Box
            style={{
              textAlign: "center",
              position: "sticky",
              top: 0,
              left: 0,
              background: "#FFFFFF",
              zIndex: "99",
            }}
          >
            <Link to="/dashboard">
              <img
                src={logo}
                width="50"
                height="50"
                //   className="side-logo"
                alt="logo"
              />
            </Link>
            <Divider />
          </Box>
          {$user.hasPermission(VIEW_DASHBOARD) && (
            <ListItem button to="/" component={Link}>
              <ListItemIcon className="m-0">
                <Dashboard color="primary" />
              </ListItemIcon>
              <ListItemText primary="Dashboard" color="primary" />
            </ListItem>
          )}
          {/*{$user.hasPermission(VIEW_DASHBOARD) && (*/}
            <ListItem button to="/statistics" component={Link}>
              <ListItemIcon className="m-0">
                 <EqualizerIcon color="primary"/>
              </ListItemIcon>
              <ListItemText primary="Statistics" color="primary" />
            </ListItem>
          {/*)}*/}
          {$user.hasPermission(VIEW_JOB) && (
            <ListItem button to="/jobs" component={Link}>
              <ListItemIcon>
                <WorkOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Jobs" color="primary" />
            </ListItem>
          )}
          {$user.hasPermission(VIEW_KYC) && (
            <ListItem button to="/professional-kyc" component={Link}>
              <ListItemIcon>
                <i className="far fa-id-card" id="drawer_icon_color" />
              </ListItemIcon>
              <ListItemText primary="Professional Kyc" color="primary" />
            </ListItem>
          )}

          {$user.hasPermission(VIEW_NOTIFICATIONS) && (
            <ListItem button to="/notification" component={Link}>
              <ListItemIcon>
                <AddAlertIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Notification" color="primary" />
            </ListItem>
          )}
          {$user.hasPermission(VIEW_SUPPORT_TICKET) && (
            <ListItem button to="/support-ticket" component={Link}>
              <ListItemIcon>
                <HeadsetMicIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Support Ticket" color="primary" />
            </ListItem>
          )}
          {$user.hasPermission(VIEW_CUSTOMERS) && (
            <ListItem button to="/customer-user" component={Link}>
              <ListItemIcon>
                <PeopleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Customer User" color="primary" />
            </ListItem>
          )}
          {$user.hasPermission(VIEW_PROFESSIONALS) && (
            <ListItem button to="/professional-user" component={Link}>
              <ListItemIcon>
                <i className="fas fa-user-tie" id="drawer_icon_color" />
              </ListItemIcon>
              <ListItemText primary="Professional User" color="primary" />
            </ListItem>
          )}
          {$user.hasPermission(VIEW_EMPLOYEES) && (
            <ListItem button to="/panel-users" component={Link}>
              <ListItemIcon>
                <GroupAddIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Panel users" color="primary" />
            </ListItem>
          )}

          {$user.hasPermission(VIEW_SLIDER_IMAGES) && (
            <ListItem button to="/sliders" component={Link}>
              <ListItemIcon>
                <SlideshowIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Sliders" color="primary" />
            </ListItem>
          )}
          {$user.hasPermission(VIEW_CATEGORIES) && (
            <ListItem button to="/categories" component={Link}>
              <ListItemIcon>
                <CategoryIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Categories" color="primary" />
            </ListItem>
          )}

          {$user.hasPermission(VIEW_SERVICES) && (
            <ListItem button to="/services" component={Link}>
              <ListItemIcon>
                <StorefrontIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Service" color="primary" />
            </ListItem>
          )}

          <ListItem button to="/payments" component={Link}>
            <ListItemIcon>
              <PaymentIcon   color="primary" />
            </ListItemIcon>
            <ListItemText primary="Payments" color="primary" />
          </ListItem>

          <ListItem button to="/payment-request" component={Link}>
            <ListItemIcon>
              <PaymentIcon   color="primary" />
            </ListItemIcon>
            <ListItemText primary="Payment Request" color="primary" />
          </ListItem>

          {$user.hasPermission(VIEW_FAQ) && (
            <ListItem button to="/faqs" component={Link}>
              <ListItemIcon>
                <LiveHelpIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Faq's" color="primary" />
            </ListItem>
          )}

          {$user.hasPermission(VIEW_ABOUT) && (
            <ListItem button to="/about-us" component={Link}>
              <ListItemIcon>
                <InfoIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="About Us" color="primary" />
            </ListItem>
          )}

          <ListItem button to="/logout" component={Link}>
            <ListItemIcon>
              <LockOpenIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Log&nbsp;Out" />
          </ListItem>
        </List>
      </Box>
    </Fragment>
  );
}
