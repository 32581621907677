import React, { Fragment, useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import {
  Container,
  Typography,
  TextField,
  Grid,
  Divider,
  Box,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import JobsDialog from "../dialog/JobsDialog";
import Card from "@material-ui/core/Card";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import JobViewDialog from "../dialog/job view/JobViewDialog";
import { crud } from "../services/CrudFactory";
import moment from "moment";
import Rating from "@material-ui/lab/Rating";
import { Pagination } from "pagination-ui";
import { $realTime } from "../services/Websocket";
import { JobSkeletonUse } from "../components/SkeletonUse";
import song from "../bell/a.mp3";
import { socket } from "../services/socket";
import AddButton from "../components/AddButton";
import JobCreate from "../dialog/JobCreate";
import Statistics from "./Statistics";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "20px",
  },
  blink : {
      animation: `$blink 4s infinite`,
  },
  '@keyframes blink':{
    '0%' : {
      opacity: 1
    },
    '25%': {
      opacity: 0
    },
    '50%': {
      opacity: 1
    },
    '75%': {
      opacity: 0
    },
    '100%':{
      opacity : 1
    },
  },
  divChange : {
      backgroundColor:"#fdf1f4",
  }
}));

export default function Jobs(props) {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [assign, setAssign] = useState(null);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [jobViewModal, setJobViewModal] = useState(null);
  const [jobCreate, setJobCreate] = useState(null);
  const [music, setMusic] = useState(new Audio(song));
  // const music = {
  //     audio:new Audio(song)
  // }
  // const [state, setState] = ({
  //     audio: new Audio(song),
  //     isPlaying: false,
  // });

  const setFilter = (key, value) => {
    setFilters((prev) => {
      return {
        ...prev,
        [key]: value === "any" ? "" : value,
      };
    });
  };
  const retrieveJobs = async () => {
    try {
      setLoading(true);
      const {
        data: { orders, total },
      } = await crud.get("retrieve/web/orders", {
        ...filters,
        limit,
        page,
      });
      setOrders(orders);
      setTotal(total);
      console.log(orders,"==========orders")
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //     return $realTime.subscribe("order", "create", (order) => {
  //         setOrders(prev => {
  //             return [order, ...prev];
  //         });
  //     }).cancel;
  // }, []);

  // useEffect(() => {
  //     return $realTime.subscribe("accept_job", "update", (acceptJob) => {
  //         setOrders(prev => {
  //             const temp = [...prev];
  //             const exist = temp.find(s => s.id === acceptJob?.id);
  //             exist.status = acceptJob?.status;
  //             exist.professional = acceptJob?.professional;
  //             return temp;
  //         })
  //     }).cancel;
  // }, []);

  // useEffect(() => {
  //     return $realTime.subscribe("reject_job", "update", (rejectJob) => {
  //         setOrders(prev => {
  //             const temp = [...prev];
  //             const exist = temp.find(s => s.id === rejectJob?.id);
  //             exist.status = rejectJob?.status;
  //             exist.professional = rejectJob?.professional;
  //             return temp;
  //         })
  //     }).cancel;
  // }, []);

  // useEffect(() => {
  //     return $realTime.subscribe("order_complete", "update", (order) => {
  //         setOrders(prev => {
  //             const temp = [...prev];
  //             const exist = temp.find(s => s.id === order?.id);
  //             exist.status = order?.status;
  //             return temp;
  //         })
  //     }).cancel;
  // }, []);

  // useEffect(() => {
  //     return $realTime.subscribe("reviews", "update", (order) => {
  //         setOrders(prev => {
  //             const temp = [...prev];
  //             const exist = temp.find(s => s.id === order?.id);
  //             exist.rating = order?.rating;
  //             return temp;
  //         });
  //     }).cancel;
  // }, []);
  // useEffect(() => {
  //     return $realTime.subscribe("cancel_order", "update", (order) => {
  //         setOrders(prev => {
  //             const temp = [...prev];
  //             const exist = temp.find(s => s.id === order?.id);
  //             exist.status = order?.status;
  //             return temp;
  //         });
  //     }).cancel;
  // }, []);

  useEffect(() => {
    socket.on("web_reviews", (order) => {
      // console.log(order, "==================");
      setOrders((prev) => {
        const temp = [...prev];
        const exist = temp.find((s) => s.id === order?.orderData?.id);
        exist.rating = order?.orderData?.rating;
        return temp;
      });
    });
    return () => socket.off("web_reviews");
  }, []);

  useEffect(() => {
    socket.on("cancel_order", (order) => {
      console.log(order);
      setOrders((prev) => {
        const temp = [...prev];
        const exist = temp.find((s) => s.id === order?.order?.id);
        exist.status = order?.order?.status;
        return temp;
      });
    });
    return () => socket.off("cancel_order");
  }, []);

  useEffect(() => {
    socket.on("accept_job_web", (acceptJob) => {
      console.log(acceptJob);
      setOrders((prev) => {
        const temp = [...prev];
        const exist = temp.find((s) => s.id === acceptJob?.orderData?.id);
        exist.status = acceptJob?.orderData?.status;
        exist.professional = acceptJob?.orderData?.professional;
        return temp;
      });
    });
    return () => socket.off("accept_job_web");
  }, []);

  useEffect(() => {
    socket.on("web_reject_job", (rejectJob) => {
      setOrders((prev) => {
        const temp = [...prev];
        const exist = temp.find((s) => s.id === rejectJob?.orderData?.id);
        exist.status = rejectJob?.orderData?.status;
        exist.professional = rejectJob?.orderData?.professional;
        return temp;
      });
    });
    return () => socket.off("web_reject_job");
  }, []);

  useEffect(() => {
    socket.on("web-order", (data) => {
      setOrders((prev) => {
        return [data.orderData, ...prev];
      });
    });
    return () => socket.off("web-order");
  }, []);

  // useEffect(() => {
  //   socket.on("cancel_order", (data) => {
  //     setOrders((prev) => {
  //       const temp = [...prev];
  //       const exist = temp.find((s) => s.id === data?.id);
  //       exist.status = data?.status;
  //       return temp;
  //     });
  //   });
  //   return () => socket.off("cancel_order");
  // }, []);

  useEffect(() => {
    retrieveJobs();
  }, [filters, limit, page]);

  const classes = useStyles();

  return (
    <>
      <Appbar />
      <Drawer />
      <Container
        maxWidth="xl"
        style={{ marginBottom: "65px", marginTop: "20px" }}
      >
      
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className="font-weight-bolder">
              Jobs
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3} md={2}>
            <TextField
              select
              label="Status"
              fullWidth
              variant="outlined"
              size="small"
              value={filters.status || "any"}
              onChange={(e) => setFilter("status", e.target.value)}
            >
              <MenuItem value="any">All</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="accepted">Accepted</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              variant="outlined"
              size="small"
              color="primary"
              fullWidth
              placeholder="Mobile Number..."
              onChange={(e) => setFilter("search", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              variant="outlined"
              size="small"
              color="primary"
              fullWidth
              placeholder="Job Order no..."
              onChange={(e) => setFilter("orderId", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        {!loading ? (
          orders?.map((order) => (
            <Card className="mt-3" className={order?.status === "not assigned" ? classes.divChange:""}>
              <CardContent>
                <div className="d-flex justify-content-between align-items-center">
                  <div >
                    <Typography variant="h6" className="d-inline header__title">
                      {order.sub_category?.name} |
                    </Typography>
                    <Typography variant="h6" className="d-inline header__title">
                      &nbsp; {order.category?.name} |
                    </Typography>
                    <Typography variant="h6" className="d-inline header__title">
                      &nbsp; {order?.id} |
                    </Typography>
                    <Typography
                      variant="h6"
                      className="d-inline ml-1"
                      style={{
                        color: "#64B6F7",
                        fontSize: "16px",
                      }}
                    >
                      &nbsp;{" "}
                      <span
                        style={{
                          background:
                              order?.status === "not assigned"?
                                "#ec407a"
                              :order?.status === "pending"
                              ? "#DFCF14"
                              : order?.status === "accepted"
                              ? "#5fdba7"
                              : order?.status === "rejected"
                              ? "red"
                              : order?.status === "completed"
                              ? "#3cb1a0"
                              : "#115ECE",
                          padding: "5px 10px",
                          borderRadius: "10px",
                          fontSize: "12px",
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                        }}
                        className={order?.status === "not assigned" ? classes.blink: ""}
                      >
                        {order?.status}
                      </span>
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="body1"
                      className="font-weight-bold d-inline ml-5 text-success"
                    >
                      Accepted By :
                      <span
                        style={{
                          color: "#000",
                          fontWeight: "400",
                        }}
                      >
                        {order.professional?.name}
                      </span>
                    </Typography>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Typography
                      variant="body1"
                      className="font-weight-bold d-inline text-success"
                    >
                      Order Date :
                      <span
                        style={{
                          color: "#000",
                          fontWeight: "400",
                        }}
                      >
                        &nbsp;{moment(order?.createdAt).format(crud.dateFormat)}
                      </span>
                    </Typography>
                  </div>
                  <div>
                    <Box className="font-weight-bold d-inline text-success">
                      <Rating
                        name="read-only"
                        value={order?.rating || ""}
                        readOnly
                      />
                    </Box>

                    <Box className="float-right">
                      <IconButton
                        onClick={() => setJobViewModal(order)}
                        color="primary">
                        <VisibilityIcon />
                      </IconButton>
                    </Box>
                  </div>
                </div>
                <Divider className="mt-2 mb-2" />
                <Box className="d-flex justify-content-between align-items-center">
                  <Typography variant="caption2" style={{ fontSize: "12px" }}>
                    {order?.address?.address},{order?.address?.landmark},<br />
                    {order?.address?.city},{order?.address?.state}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h6"
                      style={{ fontSize: "16px", display: "inline" }}
                    >
                      Total&nbsp;Price: ₹
                      {order?.total -
                        +order?.discount +
                        Number(
                          order?.additional_services?.reduce((acc, item) => {
                            return Number(acc) + +item.price;
                          }, [])
                        )}
                      /-
                    </Typography>
                  </Box>
                </Box>
                <Divider className="mt-1 mb-2" />
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    color: "white",
                    fontSize: "10px",
                    paddingBottom: "1.2px",
                  }}

                  disabled={

                    order?.status === "completed" ||
                    order?.status === "pending" ||
                    order?.status === "accepted"
                      ? true
                      : false

                  }
                  size="small"
                  onClick={() => {
                    setAssign(order);
                  }}
                >
                  Job Assign
                </Button>

                <Typography
                  variant="caption2"
                  className="font-weight-bold"
                  style={{ fontSize: "13px" }}
                >
                  &nbsp;Assign To :
                </Typography>
                <Typography variant="caption2" style={{ fontSize: "12px" }}>
                  &nbsp;
                  {order.job_order_professionals
                    ?.map((item) => item.professional?.name)
                    .join(" , ")}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className="float-right font-weight-bolder"
                  style={{ fontSize: "14px" }}
                  color="primary"
                >
                  <span className="font-weight-bold text-black-50">
                    Assign By:&nbsp; {order?.assign_by?.name}
                  </span>
                  {/*<Divider/>*/}
                </Typography>
              </CardContent>
            </Card>
          ))
        ) : (
          <JobSkeletonUse limit={5} cellCount={1} />
        )}

        {!orders.length && !loading && (
          <Typography className="p-2 mt-5 w-100 text-center font-weight-bold">
            No records found
          </Typography>
        )}
        <Pagination
          limit={limit}
          limitRange
          total={total}
          page={page}
          onPageChange={setPage}
          onLimitChange={setLimit}
        />
      </Container>

      <IconButton onClick={() => {
        setJobCreate(true)
      }}>
        <AddButton/>  
      </IconButton>
      <JobViewDialog
        order={jobViewModal}
        onClose={() => setJobViewModal(null)}
        open={!!jobViewModal}
      />
      <JobCreate
          order={jobCreate}
          onClose={() => setJobCreate(null)}
          open={!!jobCreate}
      />

      <JobsDialog
        open={!!assign}
        onClose={() => setAssign(null)}
        assign={assign}
        onSave={retrieveJobs}
      />

      <Footer />
    </>
  );
}
