import React,{useEffect,useState} from "react";
import Chart from "chart.js/auto";
import {Line} from 'react-chartjs-2';

const LineGraph = (props) => {
    // const labels = ["January","February","March","April","May","June","July"];
    const labels = props.statData?.monthRevenue?.map((ele)=> ele.month);

    const data = {
        labels: labels,
        datasets: [{
            label: 'Monthly Revenue',
            // data: [65, 59, 80, 81, 56, 55, 40],
            data: props.statData?.monthRevenue?.map((ele)=> ele.total),
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        }]
    };
    const config = {
        type: 'line',
        data: data,
    };
    return (
        <>
        <Line data={data}/>
        </>

    )

}

export default LineGraph;


