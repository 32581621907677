import React, {useEffect, useState} from "react";
import {crud} from "../services/CrudFactory";
import Edit from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import {Container, Grid, Typography} from "@material-ui/core";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";


function Sliders() {
    const [loader, setLoader] = useState(true);
    const uploadedImage = React.useRef(null);
    const imageUploader = React.useRef(null);

    //second
    const uploadedImageSecond = React.useRef(null);
    const imageUploaderSecond = React.useRef(null);

    //Third
    const uploadedImageThird = React.useRef(null);
    const imageUploaderThird = React.useRef(null);

    //Four
    const uploadedImageFour = React.useRef(null);
    const imageUploaderFour = React.useRef(null);

    //Five
    const uploadedImageFive = React.useRef(null);
    const imageUploaderFive = React.useRef(null);

    //Six
    const uploadedImageSix = React.useRef(null);
    const imageUploaderSix = React.useRef(null);


    const [imageData, setImageData] = useState([]);
    const [params, setParams] = useState({});

    const getImageData = async () => {
        const {data: {query}} = await crud.get("retrieve/web/slider");
        setImageData(query);
        setLoader(false);
    }

    useEffect(() => {
        console.log(imageData, '----------image Data------------');
    }, [imageData]);


    useEffect(() => {
        getImageData();
    }, []);

    const handleImageUploadFirst = (e) => {
        const [file] = e.target.files;
        const name = e.target.name;
        const value = e.target.files[0];
        console.log(name, value);
        setParams({...params, [name]: value});
        if (file) {
            const reader = new FileReader();
            const {current} = uploadedImage;
            current.file = file;
            reader.onload = e => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    };

    const categorySumbit = async (id) => {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("image", params.image);
        await crud.post("update/web/slider", formData);
        setParams({});
    };

    const handleImageUploadSecond = (e) => {
        const [file] = e.target.files;
        const name = e.target.name;
        const value = e.target.files[0];
        setParams({...params, [name]: value});
        if (file) {
            const reader = new FileReader();
            const {current} = uploadedImageSecond;
            current.file = file;
            reader.onload = e => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    };


    const handleImageUploadThird = (e) => {
        const [file] = e.target.files;
        const name = e.target.name;
        const value = e.target.files[0];
        setParams({...params, [name]: value});
        if (file) {
            const reader = new FileReader();
            const {current} = uploadedImageThird;
            current.file = file;
            reader.onload = e => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    };
    const handleImageUploadFour = (e) => {
        const [file] = e.target.files;
        const name = e.target.name;
        const value = e.target.files[0];
        setParams({...params, [name]: value});
        if (file) {
            const reader = new FileReader();
            const {current} = uploadedImageFour;
            current.file = file;
            reader.onload = e => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageUploadFive = (e) => {
        const [file] = e.target.files;
        const name = e.target.name;
        const value = e.target.files[0];
        setParams({...params, [name]: value});
        if (file) {
            const reader = new FileReader();
            const {current} = uploadedImageFive;
            current.file = file;
            reader.onload = e => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    };


    const handleImageUploadSix = (e) => {
        const [file] = e.target.files;
        const name = e.target.name;
        const value = e.target.files[0];
        setParams({...params, [name]: value});
        if (file) {
            const reader = new FileReader();
            const {current} = uploadedImageSix;
            current.file = file;
            reader.onload = e => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    };


    return (<>
        <Appbar/>
        <Drawer/>
        <Container maxWidth="xl" style={{marginTop: "30px"}}>
            <Typography variant={"h6"} className={"font-weight-bolder mb-1"}>Slider</Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                    <input
                        type="file"
                        accept="image/*"
                        name="image"
                        onChange={handleImageUploadFirst}
                        ref={imageUploader}
                        style={{
                            display: "none"
                        }}
                    />
                    <div className="man">
                        <img
                            ref={uploadedImage}
                            src={crud.baseUrl2 + "sliders/" + imageData[0]?.image}
                            className="imgbtn"
                        />
                    </div>
                    <div className="editBtn">
                        <IconButton style={{background: "#115ECE", color: "#FFFFFF", marginRight: "5px"}}>
                            <Edit className="p-1" onClick={() => imageUploader.current.click()}/>
                        </IconButton>

                        <IconButton style={{background: "#115ECE", color: "#FFFFFF", marginRight: "5px"}}>
                            <DoneAllIcon className="p-1" onClick={() => categorySumbit(imageData[0].id)}/>
                        </IconButton>

                    </div>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <input
                        type="file"
                        accept="image/*"
                        name="image"
                        onChange={handleImageUploadSecond}
                        ref={imageUploaderSecond}
                        style={{
                            display: "none"
                        }}
                    />
                    <div className="man">
                        <img
                            ref={uploadedImageSecond}
                            src={crud.baseUrl2 + "sliders/" + imageData[1]?.image}
                            className="imgbtn"
                        />
                    </div>
                    <div className="editBtn">
                        <IconButton style={{background: "#115ECE", color: "#FFFFFF", marginRight: "5px"}}>
                            <Edit className="p-1" onClick={() => imageUploaderSecond.current.click()}/>
                        </IconButton>

                        <IconButton style={{background: "#115ECE", color: "#FFFFFF", marginRight: "5px"}}>
                            <DoneAllIcon className="p-1" onClick={() => categorySumbit(imageData[1].id)}/>
                        </IconButton>

                    </div>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <input
                        type="file"
                        accept="image/*"
                        name="image"
                        onChange={handleImageUploadThird}
                        ref={imageUploaderThird}
                        style={{
                            display: "none"
                        }}
                    />
                    <div className="man">
                        <img
                            ref={uploadedImageThird}
                            src={crud.baseUrl2 + "sliders/" + imageData[2]?.image}
                            className="imgbtn"
                        />
                    </div>
                    <div className="editBtn">
                        <IconButton style={{background: "#115ECE", color: "#FFFFFF", marginRight: "5px"}}>
                            <Edit className="p-1" onClick={() => imageUploaderThird.current.click()}/>
                        </IconButton>

                        <IconButton style={{background: "#115ECE", color: "#FFFFFF", marginRight: "5px"}}>
                            <DoneAllIcon className="p-1" onClick={() => categorySumbit(imageData[2].id)}/>
                        </IconButton>

                    </div>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <input
                        type="file"
                        accept="image/*"
                        name="image"
                        onChange={handleImageUploadFour}
                        ref={imageUploaderFour}
                        style={{
                            display: "none"
                        }}
                    />
                    <div className="man">
                        <img
                            ref={uploadedImageFour}
                            src={crud.baseUrl2 + "sliders/" + imageData[3]?.image}
                            className="imgbtn"
                        />
                    </div>
                    <div className="editBtn">
                        <IconButton style={{background: "#115ECE", color: "#FFFFFF", marginRight: "5px"}}>
                            <Edit className="p-1" onClick={() => imageUploaderFour.current.click()}/>
                        </IconButton>

                        <IconButton style={{background: "#115ECE", color: "#FFFFFF", marginRight: "5px"}}>
                            <DoneAllIcon className="p-1" onClick={() => categorySumbit(imageData[3].id)}/>
                        </IconButton>

                    </div>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <input
                        type="file"
                        accept="image/*"
                        name="image"
                        onChange={handleImageUploadFive}
                        ref={imageUploaderFive}
                        style={{
                            display: "none"
                        }}
                    />
                    <div className="man">
                        <img
                            ref={uploadedImageFive}
                            src={crud.baseUrl2 + "sliders/" + imageData[4]?.image}
                            className="imgbtn"
                        />
                    </div>
                    <div className="editBtn">
                        <IconButton style={{background: "#115ECE", color: "#FFFFFF", marginRight: "5px"}}>
                            <Edit className="p-1" onClick={() => imageUploaderFive.current.click()}/>
                        </IconButton>

                        <IconButton style={{background: "#115ECE", color: "#FFFFFF", marginRight: "5px"}}>
                            <DoneAllIcon className="p-1" onClick={() => categorySumbit(imageData[4].id)}/>
                        </IconButton>

                    </div>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <input
                        type="file"
                        accept="image/*"
                        name="image"
                        onChange={handleImageUploadSix}
                        ref={imageUploaderSix}
                        style={{
                            display: "none"
                        }}
                    />
                    <div className="man">
                        <img
                            ref={uploadedImageSix}
                            src={crud.baseUrl2 + "sliders/" + imageData[5]?.image}
                            className="imgbtn"
                        />
                    </div>
                    <div className="editBtn">
                        <IconButton style={{background: "#115ECE", color: "#FFFFFF", marginRight: "5px"}}>
                            <Edit className="p-1" onClick={() => imageUploaderSix.current.click()}/>
                        </IconButton>

                        <IconButton style={{background: "#115ECE", color: "#FFFFFF", marginRight: "5px"}}>
                            <DoneAllIcon className="p-1" onClick={() => categorySumbit(imageData[5].id)}/>
                        </IconButton>

                    </div>
                </Grid>
            </Grid>
            <Footer/>
        </Container>
    </>);
}

export default Sliders;
