import React, {useEffect, useState, useRef} from "react";
import Drawer from "./Drawer";
import "./styles.css";
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
    Grid, Table, TableHead, TableBody, TableRow,
    TableCell, TableContainer, List, ListItem,Paper
} from "@material-ui/core";
import moment from "moment";
import {toWords} from "number-to-words";
export default function JobViewDialog(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [params, setParams] = useState({
        customerDetails: true
    });
    const {order} = props;

    const setTab = (name) => {
        Object.keys(params).map(key => {
            params[key] = false;
        });
        setParams(prev => {
            return {...prev, [name]: true}
        })
    }
    return (<div className="app">
            {
                props?.open && <Drawer
                    isOpen={props?.open}
                    position="right"
                    onClose={props.onClose}
                >
                    <div style={{display: "flex", backgroundColor: "#115ECE"}}>
                        <ListItem style={{cursor: "pointer", borderBottom: params?.customerDetails && `5px solid pink`}}
                                  onClick={() => setTab("customerDetails")}
                                  className="font-weight-bold text-white">
                            Customer Details
                        </ListItem>
                        <ListItem style={{cursor: "pointer", borderBottom: params?.orderDetails && `5px solid pink`}}
                                  onClick={() => setTab('orderDetails')}
                                  className="font-weight-bold text-white">
                            Order Details
                        </ListItem>
                        <ListItem style={{cursor: "pointer", borderBottom: !!params?.invoice && `5px solid pink`}}
                                  onClick={() => setTab("invoice")} className="font-weight-bold text-white">
                            Invoice
                        </ListItem>
                        <ListItem
                            style={{cursor: "pointer", borderBottom: !!params?.assignedDetails && `5px solid pink`}}
                            onClick={() => setTab("assignedDetails")} className="font-weight-bold text-white">
                            Assigned Details
                        </ListItem>
                        <ListItem style={{cursor: "pointer", borderBottom: !!params?.other && `5px solid pink`}}
                                  onClick={() => setTab("other")} className="font-weight-bold text-white">
                            Others
                        </ListItem>
                        <ListItem style={{cursor: "pointer", borderBottom: !!params?.activityLog && `5px solid pink`}}
                                  onClick={() => setTab("activityLog")} className="font-weight-bold text-white">
                            Activity Logs
                        </ListItem>
                    </div>
                    <div className="demo-content">
                        <button type="button" onClick={() => setIsOpen(false)} className="d-none">
                            Close
                        </button>
                        {
                            !!params?.customerDetails && <Card>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <div>
                                                    <Typography variant="h6" color="secondary">
                                                    <span style={{
                                                        background: "var(--main-color)",
                                                        padding: "5px 20px",
                                                        borderRadius: "10px",
                                                        fontSize: "16px",
                                                        color: "#FFFFFF"
                                                    }}>
                                                        Customer Id
                                                    </span>
                                                        &nbsp;: <span className="h6">
                                                        {order?.customerId}
                                                    </span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <div>
                                                    <Typography variant="h6" color="secondary">
                                                    <span style={{
                                                        background: "var(--main-color)",
                                                        padding: "5px 42px",
                                                        borderRadius: "10px",
                                                        fontSize: "16px",
                                                        color: "#FFFFFF"
                                                    }}>
                                                        Name
                                                    </span>
                                                        &nbsp;: <span className="h6">
                                                        {order?.customer?.name}
                                                    </span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <div>
                                                    <Typography variant="h6" color="secondary">
                                                    <span style={{
                                                        background: "var(--main-color)",
                                                        padding: "5px 40px",
                                                        borderRadius: "10px",
                                                        fontSize: "16px",
                                                        color: "#FFFFFF"
                                                    }}>
                                                        Mobile
                                                    </span>
                                                        &nbsp;: <span className="h6">
                                                        {order?.customer?.mobile}
                                                </span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <div>
                                                    <Typography variant="h6" color="secondary">
                                                    <span style={{
                                                        background: "var(--main-color)",
                                                        padding: "5px 35px",
                                                        borderRadius: "10px",
                                                        fontSize: "16px",
                                                        color: "#FFFFFF"
                                                    }}>
                                                        Address
                                                    </span>
                                                        &nbsp;: <span className="h6">
                                                        {order?.address?.address},
                                                        {order?.address?.landmark},
                                                        {order?.address?.city},
                                                        {order?.address?.state},
                                                </span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        }

                        {
                            !!params?.orderDetails &&
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2} className="mb-2">
                                        <Grid item xs={12} sm={3} md={4}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <div>
                                                    <Typography variant="h5" color="secondary">
                                                        Order no
                                                        : <span>
                                                        {order?.id}
                                                </span>
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <Typography variant="h6" color="secondary">
                                                    <span style={{
                                                        background: `${true} ? "#DFCF14" : "#5fdba7"}`,
                                                        padding: "5px 10px",
                                                        borderRadius: "10px",
                                                        fontSize: "12px",
                                                        color: "#FFFFFF"
                                                    }}>
                                                    {true ? "Pending" : "Accepted"}
                                                    </span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={12}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <div>
                                                    <Typography variant="h5" color="secondary">
                                                        Review : <span
                                                        className="h6">
                                                        {order?.reviews}
                                                </span>
                                                    </Typography>
                                                </div>
                                                <div>
                                                    {/*<Box component="fieldset" borderColor="transparent">*/}
                                                    {/*    <Rating name="read-only"*/}
                                                    {/*            readOnly/>*/}
                                                    {/*</Box>*/}
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <Typography variant="h6" color="secondary">
                                                    <span style={{
                                                        background: "var(--main-color)",
                                                        padding: "5px 10px",
                                                        borderRadius: "10px",
                                                        fontSize: "16px",
                                                        color: "#FFFFFF"
                                                    }}>
                                                    Order Address
                                                    </span>
                                                    &nbsp;: <span className="h6">
                                                   {order?.address?.address},
                                                    {order?.address?.landmark},
                                                    {order?.address?.city},
                                                    {order?.address?.state},
                                                </span>
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <Typography variant="h6" color="secondary">
                                                    <span style={{
                                                        background: "var(--main-color)",
                                                        padding: "5px 10px",
                                                        borderRadius: "10px",
                                                        fontSize: "16px",
                                                        color: "#FFFFFF"
                                                    }}>
                                                    Schedule Date & Time
                                                    </span>
                                                    &nbsp;: <span className="h6">
                                                    {moment(order?.scheduledAt).format("ll")} &
                                                    &nbsp;
                                                    {order?.startTime} TO {order?.endTime}
                                                </span>
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <div>
                                                    <Typography variant="h6" color="secondary">
                                                    <span style={{
                                                        background: "var(--main-color)",
                                                        padding: "5px 28px",
                                                        borderRadius: "10px",
                                                        fontSize: "16px",
                                                        color: "#FFFFFF"
                                                    }}>
                                                        Category
                                                    </span>
                                                        &nbsp;: <span
                                                        className="h6">
                                                        {order?.category?.name}
                                                </span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <div>
                                                    <Typography variant="h6" color="secondary">
                                                    <span style={{
                                                        background: "var(--main-color)",
                                                        padding: "5px 10px",
                                                        borderRadius: "10px",
                                                        fontSize: "16px",
                                                        color: "#FFFFFF"
                                                    }}>
                                                        Sub-Category
                                                    </span>
                                                        &nbsp;: <span
                                                        className="h6">
                                                        {order?.sub_category?.name}
                                                </span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <div>
                                                    <Typography variant="h6" color="secondary">
                                                    <span style={{
                                                        background: "var(--main-color)",
                                                        padding: "5px 10px",
                                                        borderRadius: "10px",
                                                        fontSize: "16px",
                                                        color: "#FFFFFF"
                                                    }}>
                                                        Remark
                                                    </span>
                                                        &nbsp;: <span
                                                        className="h6">
                                                        {order?.remark}
                                                </span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>

                                    </Grid>
                                    <TableContainer>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Service Name</TableCell>
                                                    <TableCell className="text-center">Quantity (in No.)</TableCell>
                                                    <TableCell className="text-center">Tantive Price</TableCell>
                                                    <TableCell className="text-center">Final Price</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                            <span
                                                                className="d-none">
                                                            </span>
                                                {
                                                    order?.order_details?.map(item => <TableRow hover>
                                                        <TableCell>
                                                            {item?.title}
                                                        </TableCell>
                                                        <TableCell className="text-center">
                                                            {item?.quantity}
                                                        </TableCell>
                                                        <TableCell className="text-center">
                                                            {item?.quantity * item?.actualPrice}
                                                        </TableCell>
                                                        <TableCell className="text-right pr-3">
                                                            {item?.quantity * item?.marketPrice}
                                                        </TableCell>
                                                    </TableRow>)
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {
                                        !!order?.additional_services?.length && <TableContainer className="mt-3">
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Additional Service</TableCell>
                                                        <TableCell className="text-center"></TableCell>
                                                        <TableCell className="text-center">Final Price</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        order?.additional_services?.map(item => <TableRow hover>
                                                            <TableCell>
                                                                {item?.name}
                                                            </TableCell>
                                                            <TableCell className="text-center">
                                                            </TableCell>
                                                            <TableCell className="text-center pr-3=">
                                                                {item?.price}
                                                            </TableCell>
                                                        </TableRow>)
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }

                                    <TableContainer className="mt-3">
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableBody>
                                                <TableRow hover style={{background: "#ebedee"}}>
                                                    <TableCell>Sub Total</TableCell>
                                                    <TableCell
                                                        className="text-right pr-3">₹{order?.total + Number(order?.additional_services?.reduce((prev, item) => Number(prev) + item.price, [0]))}/-</TableCell>
                                                </TableRow>
                                                <TableCell>Discount</TableCell>
                                                <TableCell
                                                    className="text-right pr-3">
                                                    {order?.discount}
                                                </TableCell>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>


                                    <TableContainer>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableBody>
                                                <TableRow hover style={{background: "#ebedee"}}>
                                                    <TableCell>Grant Total</TableCell>
                                                    <TableCell className="text-right pr-3">
                                                        ₹{order?.total + Number(order?.additional_services?.reduce((prev, item) => Number(prev) + item.price, [0])) - order?.discount}/-</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        }

                        {
                            !!params?.invoice && <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Invoice
                                    </Typography>

                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <div style={{
                                            margin: '20px auto',
                                            maxWidth: '700px',
                                            boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'
                                        }}>
                                            <div style={{padding: "25px"}}>
                                                <div style={{
                                                    padding: '10px 0px 10px 0px', textAlign: 'center'
                                                }}>
                                                    {/*<img style={{width: "150px"}} src={logo}/>*/}
                                                    <Typography variant="h5"
                                                                style={{textDecorationLine: "underline"}}>Services
                                                        4 Home</Typography>
                                                </div>
                                                <div style={{padding: '0px 35px'}}>
                                                    <p style={{lineHeight: '23px'}}>
                                                        <b>Registered Office -</b> <span
                                                        style={{fontSize: "13px"}}>Office No. 354, Sector 4A, Avas Vikas Colony,
                                                                    Bodla, Agra-UP-282007</span>
                                                        <br/><br/>
                                                        <span style={{fontSize: "13px"}}>
                  GSTIN - 09ALVPL4564G1ZH
                </span>
                                                    </p>
                                                    <table border={1} width="100%"
                                                           style={{borderCollapse: 'collapse'}}
                                                           bordercolor="black">
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan={2} style={{
                                                                textAlign: 'center',
                                                                fontSize: '22px',
                                                                padding: '5px 0px'
                                                            }}>Billing Receipt
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <table style={{borderCollapse: 'collapse'}}
                                                           width="100%">
                                                        <tbody>
                                                        <tr style={{border: '1px solid black'}}>
                                                            <td><p style={{padding: '5px'}}> To,<br/>
                                                                {order?.customer?.name}
                                                                <br/>
                                                                {order?.address?.address} , {order?.address?.landmark} ,<br/>
                                                                {order?.address?.city} , {order?.address?.state}
                                                                <br/> service
                                                                provider
                                                                Name- {order?.professional?.name}
                                                                <br/> Expert
                                                                Id No. - {"S4H/"}{order?.professional?.id}</p></td>
                                                            <td style={{float: 'right'}}>
                                                                <p style={{padding: '5px'}}>Bill No.
                                                                    - {"S4H"}{order?.id}<br/>
                                                                    Date: {moment(order?.scheduledAt).format("ll")}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <h2 className="mt-2" style={{fontSize: "20px"}}>Services</h2>
                                                    <table border={1} bordercolor="black" width="100%"
                                                           style={{borderCollapse: 'collapse'}}>
                                                        <thead>
                                                        <tr style={{fontSize: '14px'}}>
                                                            <td>S.NO.</td>
                                                            <td>Service Description</td>
                                                            <td>Quantity (in No.)</td>
                                                            <td>Per Unit (In Rs.)</td>
                                                            <td>Total Rates (in Rs.)</td>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            order?.order_details?.map((item, index) => <tr style={{
                                                                fontSize: '14px', padding: '2px'
                                                            }}>
                                                                <td style={{textAlign: 'center'}}>{index + 1}</td>
                                                                <td>
                                                                    {item?.title}
                                                                </td>
                                                                <td style={{
                                                                    textAlign: 'end', padding: '3px'
                                                                }}>
                                                                    {item?.quantity}
                                                                </td>
                                                                <td style={{
                                                                    textAlign: 'end', padding: '3px'
                                                                }}>
                                                                    {item?.actualPrice}
                                                                </td>
                                                                <td style={{
                                                                    textAlign: 'end', padding: '3px'
                                                                }}>
                                                                    {item?.actualPrice * item?.quantity}
                                                                </td>
                                                            </tr>)
                                                        }

                                                       <tr style={{fontSize: '14px', padding: '2px'}}>
                                                            <td/>
                                                            <td/>
                                                            <td>Discount</td>
                                                            <td/>
                                                            <td style={{
                                                                textAlign: 'end', padding: '3px'
                                                            }}>
                                                                Rs. {order?.discount}/-
                                                            </td>
                                                        </tr>
                                                        <tr style={{fontSize: '14px', padding: '2px'}}>
                                                            <td/>
                                                            <td/>
                                                            <td>Sub Total</td>
                                                            <td/>
                                                            <td style={{
                                                                textAlign: 'end', padding: '3px'
                                                            }}>
                                                                Rs. {order?.total - order?.discount}/-
                                                            </td>
                                                        </tr>
                                                        {
                                                            !order?.additional_services?.length &&   <>
                                                            <tr style={{fontSize: '14px', padding: '2px'}}>
                                                            <td/>
                                                            <td/>
                                                            <td className="font-weight-bold">Total Payable Amount</td>
                                                            <td/>
                                                            <td style={{
                                                                textAlign: 'end', padding: '3px'
                                                            }}>
                                                                Rs. {order?.total - order?.discount}/-
                                                            </td>
                                                        </tr>
                                                                  <tr style={{fontSize: '14px', padding: '2px'}}>
                                                            <td/>
                                                            <td style={{padding: '2px'}} colSpan="4"><b>Total Price In
                                                                Words
                                                                - {toWords(Number(order?.total - order?.discount))}
                                                            </b>
                                                            </td>
                                                        </tr>
                                                        <tr style={{fontSize: '14px', padding: '2px'}}>
                                                            <td/>
                                                            <td style={{padding: '2px'}} colSpan="4">In case of any
                                                                dispute,
                                                                resolution is subject to Agra Jurisdiction
                                                            </td>
                                                        </tr>
                                                            </>
                                                        }

                                                        </tbody>
                                                    </table>

                                                    {
                                                        !!order?.additional_services.length && <>
                                                        <h2 className="mt-2" style={{fontSize: "20px"}}>
                                                       Additional Services</h2>

                                                    <table border={1} bordercolor="black" width="100%"
                                                           style={{borderCollapse: 'collapse'}}>
                                                        <thead>
                                                        <tr style={{fontSize: '14px'}}>
                                                            <td className="text-center">S.NO.</td>
                                                            <td className="text-center">Name</td>
                                                            <td className="text-center">Price</td>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            order?.additional_services?.map((item, index) => <tr style={{
                                                                fontSize: '14px', padding: '2px'
                                                            }}>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td className="text-center">
                                                                    {item?.name}
                                                                </td>
                                                                <td className="text-center">
                                                                    {item?.price}
                                                                </td>
                                                            </tr>)
                                                        }


                                                        <tr style={{fontSize: '14px', padding: '2px'}}>
                                                            <td/>
                                                            <td className="text-center font-weight-bold">Total Payble Amount</td>
                                                            <td className="text-center">
                                                                Rs. {
                                                                order?.additional_services?.reduce((prev,item)=>
                                                                    Number(prev) + item?.price,[0]) + order?.total - order?.discount}/-
                                                            </td>
                                                        </tr>

                                                        <tr style={{fontSize: '14px', padding: '2px'}}>
                                                            {/*<td/>*/}
                                                            <td style={{padding: '2px'}} colSpan="4"><b>Total Price In
                                                                Words
                                                                - {toWords(Number(order?.additional_services?.reduce((prev,item)=>
                                                                    Number(prev) + item?.price,[0]) + order?.total - order?.discount))}
                                                            </b>
                                                            </td>
                                                        </tr>
                                                        <tr style={{fontSize: '14px', padding: '2px'}}>
                                                            {/*<td/>*/}
                                                            <td style={{padding: '2px'}} colSpan="4">In case of any
                                                                dispute,
                                                                resolution is subject to Agra Jurisdiction
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                        </>
                                                    }

                                                    <p style={{
                                                        fontSize: '14px',
                                                        lineHeight: '20px',
                                                        marginTop: '10px',
                                                        paddingBottom: "20px"
                                                    }}>Note:-
                                                        Under
                                                        reverse charge applicability. 1) This invoice is
                                                        issued on
                                                        behalf of service provider S4H acts in the Acts in
                                                        the
                                                        company of an Electronic Commerce Operator as per
                                                        section
                                                        9(5) of the CGST ACT 2017<br/>
                                                        <span>2) This invoice has been signed by S4H only for limited purpose of company as Electronic Commerce operator. For Services Home</span>
                                                        <br/><br/>
                                                        This is an electorally generated invoice and does
                                                        not require a digital signature.
                                                    </p>
                                                </div>
                                                {/*<div style={{*/}
                                                {/*    backgroundColor: '#ffffff',*/}
                                                {/*    padding: '40px 60px',*/}
                                                {/*    position: 'relative'*/}
                                                {/*}}>*/}
                                                {/* <div style="position: absolute; bottom: 0; left: 0; right: 0; height: 26px; background-color: #323149;"></div> */}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>

                                </CardContent>
                            </Card>
                        }

                        {
                            !!params?.assignedDetails && <Card>
                                <CardContent>
                                    <Grid container spacing={0}>

                                        <Grid item xs={12}>
                                            <Box
                                                border={1.6}
                                                borderRadius={10}
                                                p={1}
                                                m={2}
                                                borderColor="primary.main"
                                            >
                                                <Typography variant="body2" gutterBottom>
                                                        <span
                                                            className="font-weight-bolder">Order Accepted : </span>
                                                    {order?.professional?.name}
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box
                                                border={1.6}
                                                borderRadius={10}
                                                p={1}
                                                m={2}
                                                borderColor="primary.main"
                                            >
                                                <Typography variant="body2" gutterBottom>
                                                    <span className="font-weight-bolder">Assigned To : </span>
                                                    {
                                                        order?.job_order_professionals?.map(item=>item.professional?.name).join(",")
                                                    }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        }

                        {
                            !!params?.other &&
                            <Card>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <div>
                                                    <Typography variant="h6" color="secondary">
                                                        <span style={{
                                                            background: "var(--main-color)",
                                                            padding: "5px 28px",
                                                            borderRadius: "10px",
                                                            fontSize: "16px",
                                                            color: "#FFFFFF"
                                                        }}>
                                                            Carry Id Card
                                                        </span>
                                                        &nbsp;: <span
                                                        className="h6">{ order?.isIdCard ? "Yes" : "No"}</span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <div>
                                                    <Typography variant="h6" color="secondary">
                                                        <span style={{
                                                            background: "var(--main-color)",
                                                            padding: "5px 15px",
                                                            borderRadius: "10px",
                                                            fontSize: "16px",
                                                            color: "#FFFFFF"
                                                        }}>
                                                            Company T-Shirt
                                                        </span>
                                                        &nbsp;: <span
                                                        className="h6">{order?.isMask ? "Yes" : "No"}</span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <div>
                                                    <Typography variant="h6" color="secondary">
                                                        <span style={{
                                                            background: "var(--main-color)",
                                                            padding: "5px 25px",
                                                            borderRadius: "10px",
                                                            fontSize: "16px",
                                                            color: "#FFFFFF"
                                                        }}>
                                                           Wearing Mask
                                                        </span>
                                                        &nbsp;: <span
                                                        className="h6">{order?.isTshirt ? "Yes" : "No"}</span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div className="d-flex d-inline justify-content-between">
                                                <div>
                                                    <Typography variant="h6" color="secondary">
                                                        <span style={{
                                                            background: "var(--main-color)",
                                                            padding: "5px 45px",
                                                            borderRadius: "10px",
                                                            fontSize: "16px",
                                                            color: "#FFFFFF"
                                                        }}>
                                                            Payment
                                                        </span>
                                                        &nbsp;: <span
                                                        className="h6">
                                                        {order?.paymentType}
                                                    </span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        }

                        {
                            !!params?.activityLog &&
                            <>
                                <Typography variant="subtitle1" className="pb-1">
                                    Activity Logs
                                </Typography>

                               <Table component={Paper} elevation={2}>
                                   <TableHead>
                                       <TableRow
                                           >
                                           <TableCell>
                                               Description
                                           </TableCell>
                                           <TableCell>
                                              Date
                                           </TableCell>
                                       </TableRow>
                                   </TableHead>
                                   <TableBody>
                                       {
                                           order?.activity_logs?.map(item=><TableRow>
                                           <TableCell>{item?.description}</TableCell>
                                           <TableCell>{moment(item?.createdAt).format("DD-MM-YYYY h:mm:ss a")}</TableCell>
                                       </TableRow>)
                                       }
                                   </TableBody>
                               </Table>
                        {/*        <Box className="bg-white p-2">*/}
                        {/*            <Typography style={{fontSize: "16px"}}>Job Created*/}
                        {/*                : <span><b>*/}
                        {/*                </b> created job for <b>*/}
                        {/*                </b> inside <b>Sub_category_name</b> and category is*/}
                        {/*                <span style={{fontSize: "12px"}}*/}
                        {/*                      className="float-right">*/}
                        {/*                </span>*/}
                        {/*                </span>*/}
                        {/*            </Typography>*/}
                        {/*        </Box>*/}

                        {/*        <Box className="bg-white p-2 mt-1">*/}
                        {/*            <Typography style={{fontSize: "16px"}}><b>Job Order</b>*/}
                        {/*                : <span><b></b> created job for <b></b>*/}
                        {/*                    inside <b>Sub_category_name</b> and category is <b>*/}

                        {/*                </b></span>*/}
                        {/*                <span style={{fontSize: "12px"}}*/}
                        {/*                      className="float-right">*/}
                        {/*                            </span>*/}
                        {/*            </Typography>*/}
                        {/*        </Box>*/}

                        {/*        <Box className="bg-white p-2 mt-1">*/}
                        {/*            <Typography style={{fontSize: "16px"}}><b>Assign To</b>*/}
                        {/*                : <span>*/}
                        {/*                                <b>*/}

                        {/*    <span>*/}

                        {/*    </span>*/}
                        {/*</b>*/}
                        {/*                        </span>*/}
                        {/*                <span*/}
                        {/*                    className="d-inline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Assign By: {props.item?.job_order_professional?.user?.name}</span>*/}
                        {/*                <span style={{fontSize: "12px"}}*/}
                        {/*                      className="float-right">*/}

                        {/*                            </span>*/}
                        {/*            </Typography>*/}
                        {/*        </Box>*/}


                        {/*        <Box className="bg-white p-2 mt-1">*/}
                        {/*            <Typography style={{fontSize: "16px"}}><b>Accepted*/}
                        {/*                By</b>:*/}
                        {/*                &nbsp;*/}

                        {/*                <span style={{fontSize: "12px"}}*/}
                        {/*                      className="float-right">*/}
                        {/*                </span>*/}
                        {/*            </Typography>*/}
                        {/*        </Box>*/}
                            </>
                        }
                    </div>
                </Drawer>
            }

        </div>
    );
}
