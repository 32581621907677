import React, {useEffect, useState} from "react";
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Divider,
    Slide,
    TextField,
    Button,
    Dialog,
    IconButton,
    Tooltip,
    InputAdornment,
    Typography,
    TextareaAutosize
} from "@material-ui/core";
import {crud} from "../services/CrudFactory";
import {CloudUpload, Add, Close} from '@material-ui/icons';

export default function SubCategoryDialog(props) {
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState([
        {note: ""}
    ]);
    const [inclusions, setInclusions] = useState([
        {inclusion: ""}
    ]);

    const InputChange = (e) => {
        const {name, value} = e.target;
        setParams({...params, [name]: value});
    }

    const submit = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("id", params?.id || "");
            formData.append("name", params.name || "");
            formData.append("image", params.image || "");
            formData.append("notes", JSON.stringify(notes) || []);
            formData.append("inclusions", JSON.stringify(inclusions) || []);
            formData.append("description", params.description || "");
            formData.append("categoryId", props.categoryId || "");
            await crud.post(params?.id ? "update/web/sub-category" : "create/web/sub-category", formData);
            await props.onClose();
            setNotes([{note: ""}]);
            setInclusions([{inclusion: ""}]);
            await props.subCategories();
        } finally {
            setLoading(false);
        }
    };
    return (<div>
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={props.open}
            fullWidth
            TransitionComponent={Slide}
            maxWidth={"md"}
            TransitionProps={{
                onEnter: () => {
                    if (props?.subCategory?.id) {
                        if (props.subCategory.inclusions?.length) {
                            setInclusions(props.subCategory.inclusions);
                        }
                        if (props.subCategory.notes?.length) {
                            setNotes(props.subCategory.notes);
                        }
                    }
                    setParams(props?.subCategory);

                }
            }}
        >
            <DialogTitle>
                <Typography variant="h5">{params?.id ? "Update SubCategory" : "Create SubCategory"}</Typography>
            </DialogTitle>
            <Divider/>

            <DialogContent className="mt-2 mb-2">
                <Grid container spacing={2}>
                    <Grid item className="mt-2" xs={12} md={6} lg={6} sm={6}>
                        <div>
                            <input
                                onChange={(e) => {
                                    const image = e.target.files[0];
                                    setParams(prev => {
                                        return {...prev, "image": image}
                                    });
                                }}
                                accept="image/*"
                                style={{display: "none"}}
                                id="contained-button-file"
                                multiple
                                type="file"
                            />
                            <label htmlFor="contained-button-file" style={{width: "100%"}}>
                                <Button variant="contained" component="span" fullWidth color="primary">
                                    Upload Image
                                </Button>
                            </label>
                        </div>

                        {
                            params?.image?.type ? <div style={{width: "100%",}}>
                                <img className="img-fluid" style={{height: "150px", width: "100%"}}
                                     src={window.URL.createObjectURL(params.image)}/>
                            </div> : params?.image ? <div style={{width: '100%'}}>
                                <img className="img-fluid" style={{height: "150px", width: "100%"}}
                                     src={`${crud.baseUrl2}sub-categories/${params.image}`}/>
                            </div> : <div className="d-flex justify-content-center align-items-center"
                                          style={{width: "100%", background: "#e6e6e6", height: "150px"}}>
                                <CloudUpload style={{fontSize: "50px"}}/>
                            </div>
                        }

                    </Grid>
                    <Grid lg={6} xs={12} sm={6} item md={6}>
                        <TextField variant="outlined" value={params?.name || ""} onChange={InputChange}
                                   autoComplete="off" margin="dense" fullWidth label="Name"
                                   size="small" color="primary" name="name"/>
                    </Grid>

                    <Grid xs={12} className="mt-2" md={12} lg={12}>
                        <Typography variant="body2">Description</Typography>
                        <TextareaAutosize
                            maxRows={4}
                            aria-label="maximum height"
                            placeholder="Type Here"
                            label="Description"
                            onChange={InputChange}
                            name="description"
                            value={params?.description || ""}
                            style={{
                                width: "100%",
                                height: "100px",
                                borderRadius: "5px",
                                outline: "none",
                                padding: "5px",
                            }}

                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6}
                          style={{marginTop: "-48px", marginBottom: "15px", width: "100%"}}>
                        <div className="d-flex justify-content-end align-items-center mt-3 mb-2">
                            <Tooltip title="Add Notes">
                                <IconButton onClick={() => {
                                    setNotes(prev => {
                                        return [...prev, {note: ""}]
                                    });
                                }}
                                            style={{
                                                backgroundColor: "#115ECE", color: "#fff", marginTop: "31px",
                                            }}

                                >
                                    <Add/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        {
                            notes.map((note, i) => <div className="d-flex justify-content-center align-items-center">
                                <TextField
                                    id="input-with-icon-textfield"
                                    variant="outlined"
                                    fullWidth
                                    label="Note"
                                    name="note"
                                    margin="dense"
                                    value={note.note || ""}
                                    onChange={(e) => {
                                        setNotes(prev => {
                                            const {name, value} = e.target;
                                            const temp = [...prev];
                                            temp[i][name] = value;
                                            return temp;
                                        });
                                    }}
                                    size="small"
                                    InputProps={{
                                        startAdornment: (<InputAdornment position="start">
                                            <svg width="15" height="13"
                                                 viewBox="0 0 15 13"
                                                 fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M15 4.84918C15 5.54132 14.395 6.13314 13.6875 6.13314H10.7622C11.2409 6.6326 11.2997 7.59113 10.5776 8.21158C10.9962 8.8804 10.6405 9.76481 10.0957 10.0372C10.3586 11.4769 9.45275 12.125 7.96875 12.125C7.8883 12.125 7.5798 12.1191 7.5 12.1193C5.68444 12.1242 5.24725 11.2092 3.87507 10.9968C3.53309 10.9438 3.28125 10.6484 3.28125 10.3023V5.27718L3.28131 5.27715C3.28163 4.73908 3.59206 4.22571 4.11521 3.99263C4.96148 3.61194 6.91052 2.55597 7.26562 1.7273C7.49405 1.19428 7.89211 0.875234 8.4375 0.875C9.4401 0.87459 10.1295 1.90326 9.73005 2.8352C9.62508 3.08009 9.48653 3.32357 9.31541 3.56522H13.6875C14.3745 3.56522 15 4.16706 15 4.84918ZM2.8125 4.85938V10.4844C2.8125 10.8727 2.4977 11.1875 2.10938 11.1875H0.703125C0.314795 11.1875 0 10.8727 0 10.4844V4.85938C0 4.47105 0.314795 4.15625 0.703125 4.15625H2.10938C2.4977 4.15625 2.8125 4.47105 2.8125 4.85938ZM1.99219 9.78125C1.99219 9.45764 1.72986 9.19531 1.40625 9.19531C1.08264 9.19531 0.820312 9.45764 0.820312 9.78125C0.820312 10.1049 1.08264 10.3672 1.40625 10.3672C1.72986 10.3672 1.99219 10.1049 1.99219 9.78125Z"
                                                    fill="#115ECE"/>
                                            </svg>
                                        </InputAdornment>),
                                    }}
                                />
                                <div>
                                    <IconButton
                                        className="ml-2 mt-1"
                                        onClick={() => {
                                            notes.length > 1 &&
                                            setNotes(prev => {
                                                const temp = [...prev];
                                                return temp.filter((s, index) => index !== i);
                                            });
                                        }}
                                        style={{
                                            backgroundColor: "#f82121",
                                            color: "#fff",
                                        }}
                                    >
                                        <Close/>
                                    </IconButton>
                                </div>
                            </div>)
                        }

                    </Grid>


                    <Grid item xs={12} sm={6} md={6} lg={6}
                          style={{marginTop: "-48px", marginBottom: "15px", width: "100%"}}>
                        <div className="d-flex justify-content-end align-items-center mt-3 mb-2">
                            <Tooltip title="Add Inclusions">
                                <IconButton onClick={() => {
                                    setInclusions(prev => {
                                        return [...prev, {inclusion: ""}]
                                    });
                                }}
                                            style={{
                                                backgroundColor: "#115ECE", color: "#fff", marginTop: "31px",
                                            }}

                                >
                                    <Add/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        {
                            inclusions.map((inclusion, i) => <div
                                className="d-flex justify-content-center align-items-center">
                                <TextField
                                    id="input-with-icon-textfield"
                                    variant="outlined"
                                    fullWidth
                                    label="Inclusion"
                                    name="inclusion"
                                    margin="dense"
                                    value={inclusion.inclusion || ""}
                                    onChange={(e) => {
                                        setInclusions(prev => {
                                            const {name, value} = e.target;
                                            const temp = [...prev];
                                            temp[i][name] = value;
                                            return temp;
                                        });
                                    }}
                                    size="small"
                                    InputProps={{
                                        startAdornment: (<InputAdornment position="start">
                                            <svg width="15" height="13"
                                                 viewBox="0 0 15 13"
                                                 fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M15 4.84918C15 5.54132 14.395 6.13314 13.6875 6.13314H10.7622C11.2409 6.6326 11.2997 7.59113 10.5776 8.21158C10.9962 8.8804 10.6405 9.76481 10.0957 10.0372C10.3586 11.4769 9.45275 12.125 7.96875 12.125C7.8883 12.125 7.5798 12.1191 7.5 12.1193C5.68444 12.1242 5.24725 11.2092 3.87507 10.9968C3.53309 10.9438 3.28125 10.6484 3.28125 10.3023V5.27718L3.28131 5.27715C3.28163 4.73908 3.59206 4.22571 4.11521 3.99263C4.96148 3.61194 6.91052 2.55597 7.26562 1.7273C7.49405 1.19428 7.89211 0.875234 8.4375 0.875C9.4401 0.87459 10.1295 1.90326 9.73005 2.8352C9.62508 3.08009 9.48653 3.32357 9.31541 3.56522H13.6875C14.3745 3.56522 15 4.16706 15 4.84918ZM2.8125 4.85938V10.4844C2.8125 10.8727 2.4977 11.1875 2.10938 11.1875H0.703125C0.314795 11.1875 0 10.8727 0 10.4844V4.85938C0 4.47105 0.314795 4.15625 0.703125 4.15625H2.10938C2.4977 4.15625 2.8125 4.47105 2.8125 4.85938ZM1.99219 9.78125C1.99219 9.45764 1.72986 9.19531 1.40625 9.19531C1.08264 9.19531 0.820312 9.45764 0.820312 9.78125C0.820312 10.1049 1.08264 10.3672 1.40625 10.3672C1.72986 10.3672 1.99219 10.1049 1.99219 9.78125Z"
                                                    fill="#115ECE"/>
                                            </svg>
                                        </InputAdornment>),
                                    }}
                                />
                                <div>
                                    <IconButton
                                        className="ml-2 mt-1"
                                        onClick={() => {
                                            inclusions.length > 1 &&
                                            setInclusions(prev => {
                                                const temp = [...prev];
                                                return temp.filter((s, index) => index !== i);
                                            });
                                        }}
                                        style={{
                                            backgroundColor: "#f82121",
                                            color: "#fff",
                                        }}
                                    >
                                        <Close/>
                                    </IconButton>
                                </div>
                            </div>)
                        }

                    </Grid>
                </Grid>
            </DialogContent>
                        <Divider/>

            <DialogActions className="p-2">
                <Button
                    variant="outlined"
                    // onClick={CreateSubmit}
                    onClick={() => {
                        setInclusions([{inclusion: ""}]);
                        setNotes([{note: ""}])
                        props.onClose();
                    }}
                    color="primary"
                    size="small"
                    style={{textTransform: "none"}}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => submit()}
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                    style={{textTransform: "none"}}
                >
                    {
                        !params?.id ? (loading ? "Creating..." : "Create") : (loading ? "Updating..." : "Update")
                    }
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
}
