import React, {useState,memo} from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {
    DialogTitle,
    DialogContent,
    IconButton,
    Tooltip,
    Divider, CardActionArea, CardMedia, Card,
} from "@material-ui/core";
import {crud} from "../services/CrudFactory";
import _ from "lodash";
import CloseIcon from '@material-ui/icons/Close';

 function ImagePreview(props) {
    const [params, setParams] = useState({});
    const {path, defaultImage, imageSize, title, imageUrl, close, open} = props;

    return (
        <div>
            <Dialog
                onClose={close}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={"sm"}
                TransitionProps={
                    {
                        onEnter: () => {
                            setParams(imageUrl);
                        }
                    }
                }
            >
                <DialogTitle>
                    <Typography variant="h6" className="d-inline">{title}</Typography>
                    <Tooltip title="Close">
                        <IconButton onClick={close} className="ml-5 float-right" aria-label="delete">
                            <CloseIcon color="primary"/>
                        </IconButton>
                    </Tooltip>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <Card>
                        <CardActionArea>
                            <CardMedia
                                style={{height: "250px", width: imageSize}}
                                image={!_.isEmpty(params) ? crud.baseUrl2 + path + params : defaultImage}
                            />
                        </CardActionArea>
                    </Card>
                </DialogContent>
            </Dialog>
        </div>
    );
}
export default memo(ImagePreview);